import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import CustomTimeInput from "./CustomTimeInput";
import axios from "axios";
import { Trash3 } from "react-bootstrap-icons";
import { toast } from "react-toastify";

const EditItinerary = ({ show, handleClose,itineraries = [],scheduleId, token,durationDays}) => {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const [editedItineraries, setEditedItineraries] = useState([]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    console.log("duration========>12365",durationDays)
    if (Array.isArray(itineraries) && itineraries.length > 0) {
      setEditedItineraries(
        [...itineraries].sort((a, b) => a.dayNumber - b.dayNumber)
      );
    } else {
      setEditedItineraries([]);
    }
  }, [itineraries]);

  const handleFieldChange = (index, field, value) => {
    const newItineraries = [...editedItineraries];

    if (field === "dayNumber") {
      const dayNumberValue = parseInt(value, 10);

      if (
        isNaN(dayNumberValue) ||
        dayNumberValue < 1 ||
        dayNumberValue > durationDays
      ) {
        setErrors((prevErrors) => {
          const newErrors = [...prevErrors];
          if (!newErrors[index]) newErrors[index] = {};
          newErrors[
            index
          ].dayNumber = `Day number must be between 1 and ${durationDays}.`;
          return newErrors;
        });
        return;
      }

      setErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        if (newErrors[index] && newErrors[index].dayNumber) {
          delete newErrors[index].dayNumber;
        }
        return newErrors;
      });
    }

    newItineraries[index][field] = value;
    setEditedItineraries(newItineraries);
  };

  // const handleFieldChange = (index, field, value) => {
  //   console.log("setEditedItineraries=========>",editedItineraries)

  //   const newItineraries = [...editedItineraries];
  //   newItineraries[index][field] = value;
  //   setEditedItineraries(newItineraries);
  //   setErrors((prevErrors) => {
  //     const newErrors = [...prevErrors];
  //     if (newErrors[index] && newErrors[index][field]) {
  //       delete newErrors[index][field];
  //       if (Object.keys(newErrors[index]).length === 0) {
  //         newErrors[index] = null;
  //       }
  //     }
  //     console.log("wasedrftghjk=========>",editedItineraries)

  //     return newErrors;
  //   });
  // };

  const handleAddRow = () => {
    console.log("Tour Duration: ", durationDays); 

    const newItinerary = {
      dayNumber: "",
      description: "",
      startTime: "",
      endTime: "",
    };
    setEditedItineraries([...editedItineraries, newItinerary]);
  };

  const handleRemoveRow = async (index) => {
    const itineraryId = editedItineraries[index]?.itineraryId;
    try {
      const response = await axios.post(
        `${BaseAPI}/tour/delete/itineraries/${scheduleId}`,
        [itineraryId],
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        // Remove the itinerary and reset the errors for the deleted row
        setEditedItineraries((prevItineraries) =>
          prevItineraries.filter((_, i) => i !== index)
        );
        setErrors((prevErrors) => {
          const newErrors = [...prevErrors];
          newErrors.splice(index, 1); 
          return newErrors;
        });
        toast.success("Itinerary deleted successfully!");
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          general: "Failed to delete itinerary.",
        }));
        toast.error("Failed to delete itinerary.");
      }
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        general: "An error occurred while deleting the itinerary.",
      }));
    }
  };
  
  const handleUpdate = async () => {
    let hasError = false;
    const newErrors = [];
  
    const dayNumbers = editedItineraries.map((itinerary) => itinerary.dayNumber);
  
    const dayNumberCount = {};
  
    dayNumbers.forEach((dayNumber, index) => {
      if (dayNumberCount[dayNumber]) {
        dayNumberCount[dayNumber].push(index);
      } else {
        dayNumberCount[dayNumber] = [index];
      }
    });
  
    for (const [dayNumber, indices] of Object.entries(dayNumberCount)) {
      if (indices.length > 1) {
        if (!newErrors[indices[0]]) newErrors[indices[0]] = {};
        newErrors[indices[0]].dayNumber = "Day number cannot be same.";
        hasError = true;
      }
    }
  
    editedItineraries.forEach((item, index) => {
      if (!item.dayNumber) {
        hasError = true;
        if (!newErrors[index]) newErrors[index] = {};
        newErrors[index].dayNumber = "Day number cannot be empty.";
      }
    });
  
    setErrors(newErrors);
  
    if (hasError) {
      return;
    }
  
    if (!scheduleId) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        general: "Schedule ID is missing.",
      }));
      return;
    }
  
    const payload = editedItineraries.map(
      ({ dayNumber, description, startTime, endTime }) => ({
        dayNumber,
        description,
        startTime,
        endTime,
      })
    );
  
    try {
      const response = await fetch(
        `${BaseAPI}/tour/update/itinerary/schedule/${scheduleId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error updating itinerary: ${errorText}`);
      }
      toast.success("Itinerary updated successfully!");
      handleClose();
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        general: "Failed to update itinerary.",
      }));
      toast.error("Failed to update itinerary.");
    }
  };
  
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="font-common">Update Itinerary</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover className="font-common">
          <thead>
            <tr>
              <th>Sr No</th>
              <th>Day</th>
              <th>Description</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {editedItineraries.length > 0 ? (
              editedItineraries.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <Form.Control
                      type="number"
                      value={item.dayNumber}
                      onChange={(e) =>
                        handleFieldChange(index, "dayNumber", e.target.value)
                      }
                    />
                    {errors[index] && errors[index].dayNumber && (
                      <div className="text-danger">
                        {errors[index].dayNumber}
                      </div>
                    )}
                  </td>
                  <td>
                    <Form.Control
                      as="textarea"
                      rows={1}
                      value={item.description}
                      onChange={(e) =>
                        handleFieldChange(index, "description", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <CustomTimeInput
                      value={item.startTime || ""}
                      onChange={(value) =>
                        handleFieldChange(index, "startTime", value)
                      }
                    />
                  </td>
                  <td>
                    <CustomTimeInput
                      value={item.endTime || ""}
                      onChange={(value) =>
                        handleFieldChange(index, "endTime", value)
                      }
                    />
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => handleRemoveRow(index)}
                    >
                      <Trash3 />
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No itineraries available for this schedule.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Button variant="success" className="mt-3" onClick={handleAddRow}>
          Add Itinerary
        </Button>
        {errors.general && (
          <div className="text-danger mt-2">{errors.general}</div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {editedItineraries.length > 0 && (
          <Button variant="primary" onClick={handleUpdate}>
            Update
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default EditItinerary;
