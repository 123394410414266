import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/assets/scss/style.scss";
import '@mdi/font/css/materialdesignicons.min.css';
import "@iconscout/unicons/css/line.css";
import feather from "feather-icons";
import "simplebar/dist/simplebar.css";
import "./App.css";
import Dashboard from "./componets/dashboard/dashboard";
import Sidebar from "./componets/sidebar/sidebar";
import SignUp from "./componets/authpages/SignUp";
import { HashRouter, Route, Routes } from "react-router-dom";
import TourCreate from "./componets/tour/TourCreate";
import SignIn from "./componets/authpages/SignIn";
import ScheduledTour from "./componets/tour/ScheduledTour";
import BookedTour from "./componets/tour/BookedTour";
import Itinerary from "./componets/tour/Itinarary";
import AdminDashboard from "./componets/Admin/adminDashboard";
import PrivateRoute from "./componets/services/privateRoute";
import BookedTours from "./componets/Admin/bookedTour/BookedTour";
import ThemeSettings from "./componets/Admin/themeSetting/themesettings";
import AdminTours from "./componets/Admin/adminTours/AdminTours";
import CreateVendor from "./componets/Admin/EnterpriseTourCompany/createVendor";
import EnterpriseCompanyTour from "./componets/Admin/EnterpriseTourCompany/EnterpriseCompanyTour";
import TourDetails from "./componets/tour/TourDetails";
import AddSchedule from "./componets/tour/Itinarary/AddSchedule";

function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route path="/" element={<SignIn />} />
         <Route path="/signup" element={<SignUp />} />

          <Route path="/vendor-dashboard" element={<PrivateRoute element={Dashboard} />} />
          <Route path="/admin-dashboard" element={<PrivateRoute element={AdminDashboard} />} />
          <Route path="/create-tour" element={<PrivateRoute element={TourCreate} />} />
          <Route path="/scheduled-tour" element={<PrivateRoute element={ScheduledTour} />} />
          <Route path="/booked-tour" element={<PrivateRoute element={BookedTour} />} />
          <Route path="/create-itinerary" element={<PrivateRoute element={Itinerary} />} />
          

          <Route path="/all-vendors" element={<PrivateRoute element={EnterpriseCompanyTour} />} />

          <Route path="/booked-tours" element={<PrivateRoute element={BookedTours} />} />
          <Route path="/theme-setting" element={<PrivateRoute element={ThemeSettings} />} />
          <Route path="/admin-tours" element={<PrivateRoute element={AdminTours} />} />
          <Route path="/tourdetails" element={<PrivateRoute element={TourDetails} />} />
          <Route path="/add-schedule" element={<PrivateRoute element={AddSchedule} />} />

        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
