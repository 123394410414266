import React from "react";
import { Form, Button, Table, Container, Col, Row, Modal } from "react-bootstrap";

const TourView=({selectedTour,showViewPopup,handleClose})=>{
  console.log("view",selectedTour)
    return(
        <>
        <Modal className="modal-lg mt-5 " show={showViewPopup} onClick={handleClose}>
        <div className="bg-white">
          <Modal.Header closeButton>
            <Modal.Title> View Tour</Modal.Title>
          </Modal.Header>
          <Modal.Body border>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label className="tour-form-label ">
                    Tour Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={selectedTour?.title}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label className="tour-form-label ">
                    Max Participants
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={selectedTour?.maxParticipants}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label className="tour-form-label ">
                    Description
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={selectedTour?.description}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label className="tour-form-label ">
                    Theme Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={selectedTour?.tourTheme?.map((theme, index) => (
                      <React.Fragment key={index}>
                        {theme.theme?.themeName}
                        {index < selectedTour.tourTheme.length - 1 && ", "}
                        <br />
                      </React.Fragment>
                    ))}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label className="tour-form-label ">
                    Description
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={selectedTour?.description}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label className="tour-form-label ">
                    Category Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={selectedTour?.tourTheme?.map((theme, index) => (
                      <React.Fragment key={index}>
                        {theme.category?.categoryName}
                        {index < selectedTour.tourTheme.length - 1 && ", "}
                        <br />
                      </React.Fragment>
                    ))}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label className="tour-form-label ">
                    Sub Category Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={selectedTour?.subCategoryName}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label className="tour-form-label ">
                    Tour Images
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={selectedTour?.tourAssets.map((tours, index) => (
                      <React.Fragment key={index}>
                        <Col md={4}>
                          <img
                            src={tours[0]?.assetUrl}
                            width="80px"
                            height="80px"
                            alt="Subcategory Image"
                          />
                        </Col>
                      </React.Fragment>
                    ))}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button
              variant="btn btn-secondary w-25 close mt-4 "
              onClick={handleClose}
            >
              Close
            </Button>
          </Modal.Body>
        </div>
      </Modal>
        </>
    )
}
export default TourView;