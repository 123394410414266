import React, { useEffect, useState } from "react";
import Sidebar from "../sidebar/sidebar";
import Navbar from "../common/navbar";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [valueFromNavbar, setValueFromNavbar] = useState(false);

  const BaseUrl = process.env.REACT_APP_EUREKA_SERVER_ENDPOINT;

  const navigate = useNavigate();

  const handleTourClick = (e) => {
    e.preventDefault();
    navigate("/create-tour");
  };

  const handleScheduledTourClick = (e) => {
    e.preventDefault();
    navigate("/scheduled-tour");
  };

  const handleBookedTourClick = (e) => {
    e.preventDefault();
    navigate("/booked-tour");
  };

  const handleItineraryTourClick = (e) => {
    e.preventDefault();
    navigate("/create-itinerary");
  };

  

  const handleValueChange = (value) => {
    setValueFromNavbar(value);
  };

 

  return (
    <>
      <div>
        <div>
          <Sidebar />
        </div>
        <div>
          <Navbar onValueChange={handleValueChange} />
          <div
            className={`${
              !valueFromNavbar ? "layout-navbar-shifted" : "layout-navbar-full"
            }`}
          >
            <div className="layout-specing">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                 
                </div>
              </div>

              <div className="row ms-2 me-2">
                <div className="col mt-4 dashboard-main"  onClick={handleTourClick} >
                  <span className="features feature-primary d-flex justify-content-between align-items-center rounded shadow p-3">
                    <div className="d-flex align-items-center">
                      <div className="icon text-center rounded-pill">
                        <i className="mdi mdi-map fs-4 mb-0"></i>
                      </div>
                      <div className="flex-1 ms-3" >
                        <h6 className="mb-0 text-muted font-common">Tour</h6>
                        
                      </div>
                    </div>

                  </span>
                </div>

                <div className="col mt-4 dashboard-main" onClick={handleScheduledTourClick} >
                  <span className="features feature-primary d-flex justify-content-between align-items-center rounded shadow p-3">
                    <div className="d-flex align-items-center">
                      <div className="icon text-center rounded-pill">
                        <i className="mdi mdi-calendar-check fs-4 mb-0"></i>
                      </div>
                      <div className="flex-1 ms-3">
                        <h6 className="mb-0 text-muted font-common">Scheduled Tour</h6>
                       </div>
                    </div>

                  </span>
                </div>

                <div className="col mt-4 dashboard-main"onClick={handleBookedTourClick} >
                  <span className="features feature-primary d-flex justify-content-between align-items-center rounded shadow p-3">
                    <div className="d-flex align-items-center">
                      <div className="icon text-center rounded-pill">
                        <i className="mdi mdi-ticket fs-4 mb-0"></i>
                      </div>
                      <div className="flex-1 ms-3">
                        <h6 className="mb-0 text-muted font-common">Booked Tour</h6>
                      
                      </div>
                    </div>
                  </span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
