import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Carousel,
  Button,
  Modal,
  Form,
  Table,
  InputGroup,
  Dropdown,
} from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import Select from "react-select";
import CurrencyFlagImage from "react-currency-flags";
import cc from "currency-codes";


const AddSchedule = ({selectedTourID,
  showAddScheduleModal,
  setShowAddScheduleModal,
  fetchScheduleDataByTourId
  //setShowAddScheduleModal;(flagtoSet)
}) => {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;

  const [selectedTourId, setSelectedTourId] = useState("");

  const [showModal, setShowModal] = useState(false);
  const { userData, token } = useSelector((state) => state);

  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const navigate = useNavigate();
  const [currencyList, setCurrencyList] = useState([]);

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTour, setSelectedTour] = useState(null);
  const [tourToDelete, setTourToDelete] = useState(null);
  const [loadingFeatured, setLoadingFeatured] = useState(false);
  const [loadingSnapshot, setLoadingSnapshot] = useState(false);
  const [errors, setErrors] = useState({});
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const [formData, setFormData] = useState({
    scheduleTitle: "",
    startDate: "",
    duration: "",
    durationTime: "",
    price: "",
    currency: "",
    discount: "",
  });

  const handleSelectChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      currency: selectedOption ? selectedOption.value : "",
    }));
    setErrors({
      ...errors,
      currency: "",
    });
  };

  useEffect(() => {
    setSelectedTourId(selectedTourID);
    setShowModal(showAddScheduleModal);
  }, [showAddScheduleModal]);


  useEffect(() => {
    const options = cc.codes().map((code) => ({
      value: code,
      label: `${code}`,
    }));
    setCurrencyOptions(options);
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    setShowAddScheduleModal(false);
  };

  // const showModalPopup = () => {
  //   setShowModal(true);
  // };


  const handleChange = (e) => {
    const { name, value } = e.target;

    if (
      name === "startDate" &&
      new Date(value) < new Date().setHours(0, 0, 0, 0)
    ) {
      setErrors({
        ...errors,
        [name]: "The start date cannot be in the past.",
      });
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.scheduleTitle) {
      newErrors.scheduleTitle = "Schedule title is required.";
    }
    if (!formData.startDate) {
      newErrors.startDate = "Start date is required.";
    }
    if (!formData.duration || formData.duration <= 0) {
      newErrors.duration = "Duration must be greater than 0.";
    }
    if (!formData.durationTime) {
      newErrors.durationTime = "Duration time is required.";
    }
    if (
      !formData.discount ||
      formData.discount < 0 ||
      formData.discount > 100
    ) {
      newErrors.discount = "Discount must be between 0 and 100% ";
    }
    if (!formData.price || isNaN(formData.price) || formData.price <= 0) {
      newErrors.price = "Price must be a valid number greater than 0.";
    }
    if (!formData.currency) {
      newErrors.currency = "Currency is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      const response = await fetch(
        `${BaseAPI}/tour/${selectedTourId}/schedule`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        toast.success("Schedule created successfully");
        // navigate("/scheduled-tour");
        // fetchAllToursByVendorId();
        setShowSubmitButton(true);
        setFormData({
          scheduleTitle: "",
          startDate: "",
          duration: "",
          durationTime: "",
          price: "",
          // currency:"",
          discount: "",
        });
        fetchScheduleDataByTourId(selectedTourID)
        handleCloseModal();
      } else {
        const errorText = await response.text();
        console.error("Failed to create schedule:", errorText);
        toast.error("Failed to create schedule");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while creating the schedule");
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "4px",
      borderRadius: "2px",
      border: "1px solid #ced4da",
      boxShadow: "none",
      width: "180px",
    }),
    option: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      padding: "8px 12px",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "8px",
      overflow: "hidden",
      zIndex: 9999,
    }),
  };

  const formatOptionLabel = ({ value, label }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <CurrencyFlagImage
        currency={value}
        size="lg"
        style={{ marginRight: 10 }}
      />
      {label}
    </div>
  );
  

  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal} size="lg" className="font-common">
        <Modal.Header closeButton>
          <Modal.Title className="font-common">Schedule Tour</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card style={{ border: "none" }}>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <div className="form-group font-common">
                  <Form.Group>
                    <Form.Label>Schedule Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="scheduleTitle"
                      value={formData.scheduleTitle}
                      onChange={handleChange}
                      placeholder="Enter schedule title"
                    />
                    {errors.scheduleTitle && (
                      <small className="text-danger">
                        {errors.scheduleTitle}
                      </small>
                    )}
                  </Form.Group>

                  <Row className="mt-1">
                    <Col md={6}>
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        id="startDate"
                        name="startDate"
                        value={formData.startDate}
                        min={new Date().toISOString().split("T")[0]}
                        onChange={handleChange}
                      />
                      {errors.startDate && (
                        <small className="text-danger">
                          {errors.startDate}
                        </small>
                      )}
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="duration">
                        <Form.Label>Duration in days</Form.Label>
                        <Form.Control
                          type="number"
                          name="duration"
                          placeholder="Enter duration in days "
                          value={formData.duration}
                          onChange={handleChange}
                        />
                        {errors.duration && (
                          <small className="text-danger">
                            {errors.duration}
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-1">
                    <Col md={6}>
                      <Form.Group controlId="durationTime">
                        <Form.Label>Duration in time</Form.Label>
                        <Form.Control
                          type="text"
                          name="durationTime"
                          placeholder="Enter Duration Time"
                          value={formData.durationTime}
                          onChange={handleChange}
                        />
                        {errors.durationTime && (
                          <small className="text-danger">
                            {errors.durationTime}
                          </small>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Label>Discount (%)</Form.Label>
                      <Form.Control
                        type="text"
                        id="discount"
                        name="discount"
                        placeholder="Discount in %"
                        value={formData.discount}
                        onChange={handleChange}
                      />
                      {errors.discount && (
                        <small className="text-danger">{errors.discount}</small>
                      )}
                    </Col>
                  </Row>

                  <div className="row mt-1">
                    <div className="col-md-6 mb-3">
                      <Form.Label>Price</Form.Label>
                      <InputGroup>
                        <div style={{ width: "35%" }}>
                          <Select
                            id="currency"
                            name="currency"
                            options={currencyOptions}
                            value={currencyOptions.find(
                              (option) => option.value === formData.currency
                            )}
                            onChange={handleSelectChange}
                            placeholder="Select CUR"
                            required
                            styles={customStyles}
                            formatOptionLabel={formatOptionLabel}
                          />
                        </div>
                        <Form.Control
                          type="text"
                          id="price"
                          name="price"
                          placeholder="Enter Price"
                          value={formData.price}
                          onChange={handleChange}
                        />
                      </InputGroup>
                      {errors.price && (
                        <small className="text-danger">{errors.price}</small>
                      )}
                    </div>
                  </div>
                  
                </div>
                <div className="mt-3 d-flex justify-content-end">
                  <Button type="submit" className="me-2 btn-primary">
                    Submit
                  </Button>
                  <Button variant="secondary" onClick={handleCloseModal}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddSchedule;
