import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import AuthService from "../services/auth.service";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/scss/style.scss';
import '@mdi/font/css/materialdesignicons.min.css';

const Navbar = ({onValueChange}) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true); 

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (userData && userData.data) {
            setUser(userData.data);
        }
    }, []);

    const handleLogout = () => {
        AuthService.Logout();
        navigate("/");
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen); 
        onValueChange(isSidebarOpen)       
        document.getElementById("sidebar").classList.toggle("active"); 
    };

    const getInitial = (name) => {
        return name ? name.charAt(0).toUpperCase() : "?";
    };

    
    const createInitialsImage = (name) => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = 32;
        canvas.height = 32;

        // Background
        context.fillStyle = "#6c757d";
        context.fillRect(0, 0, canvas.width, canvas.height);

        // Initial
        context.font = "16px Arial";
        context.fillStyle = "#ffffff";
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillText(getInitial(name), canvas.width / 2, canvas.height / 2);

        return canvas.toDataURL();
    };


    return (
        <>
        <div  className={`layout-navbar page-content ${isSidebarOpen ? 'layout-navbar-shifted' : 'layout-navbar-full'}`}  style={{ position: 'sticky', top: 0, zIndex: 1000, backgroundColor: '#fff', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
            <div className="top-header position-sticky" style={{ position: 'sticky', top: 0, zIndex: 1000, backgroundColor: '#fff', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
                <div className="header-bar d-flex justify-content-between">
                    <div className="header-bar d-flex justify-content-between align-items-center">
                        <button id="close-sidebar" className="btn btn-icon btn-soft-light"  onClick={toggleSidebar}>
                            <i className="fas fa-bars"></i>
                        </button>
                        <div className="p-0 d-none d-md-block ms-5 text-center" style={{ marginLeft: "100px" }}>
                            <h4 className="font-common mt-2">Welcome To Eureka-Trip</h4>
                        </div>
                    </div>
                    <ul className="list-unstyled mb-0 d-flex align-items-center">
                        <li className="list-inline-item mb-0 ">
                           
                            {/* <i className="mdi mdi-cog me-3"></i> */}
                        </li>

                        {user && (
                            <Dropdown>
                                <Dropdown.Toggle variant="light" className="p-0 me-2">
                                    <img
                                        src={user.profilePicture || createInitialsImage(user.firstName)}
                                        className="avatar avatar-ex-small rounded"
                                        alt={user.firstName}
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dd-menu dropdown-menu-end shadow border-0 mt-3 py-3">
                                    <Dropdown.Item href="" className="d-flex align-items-center text-dark pb-3">
                                        <img
                                            src={user.profilePicture || createInitialsImage(user.firstName)}
                                            className="avatar avatar-md-sm rounded-circle border shadow"
                                            alt={user.firstName}
                                        />
                                        <div className="flex-1 ms-2">
                                            <span className="d-block">{user.firstName} {user.lastName}</span>
                                            <small className="text-muted">{user.roleName}</small>
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="" className="text-dark">
                                    <i className="mdi mdi-email me-1"></i> {user.email}
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item href="" className="text-dark" onClick={handleLogout}>
                                    <i className="mdi mdi-logout me-1"></i> Logout
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </ul>
                </div>
            </div>
            </div>
        </>
    );
};

export default Navbar;
