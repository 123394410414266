import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';

const CustomTimeInput = ({ value, onChange }) => {
  useEffect(() => {
    if (!value) {
      onChange("08:00:00");
    }
  }, [value, onChange]);

  const handleTimeChange = (e) => {
    let timeValue = e.target.value;
    if (timeValue.length === 5) {
      timeValue += ':00'; 
    }
    onChange(timeValue);
  };

  return (
    <Form.Control
      type="time"
      value={value || "08:00:00"} 
      onChange={handleTimeChange}
      step="1"
    />
  );
};

export default CustomTimeInput;
