import { Button, Table, Pagination } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import Navbar from "../../common/navbar";
import AdminSidebar from "../adminSidebar";

function EnterpriseCompanyTour() {
  const baseUrl = process.env.REACT_APP_EUREKA_SERVER_ENDPOINT;
  const [enterprises, setEnterprises] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); 
  const [valueFromNavbar, setValueFromNavbar] = useState(false);
  const [isApproving, setIsApproving] = useState(false); 


  const handleValueChange = (value) => {
    setValueFromNavbar(value);
  };

  useEffect(() => {
    fetchEnterprises();
  }, []);

  const fetchEnterprises = async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await fetch(`${baseUrl}/rest/auth/all/enterprise`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      if (response.ok) {
        setEnterprises(data.data);
      } else {
        console.error("Failed to fetch enterprises:", data.message);
      }
    } catch (error) {
      console.error("Error fetching enterprises:", error);
    }
  };

  const handleApprove = async (companyId) => {
    if (isApproving) return; 

    setIsApproving(true); 

    try {
      const token = localStorage.getItem("token");

      const response = await fetch(
        `${baseUrl}/rest/auth/approve?company_id=${companyId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (response.ok) {
        setEnterprises((prevEnterprises) =>
          prevEnterprises.map((enterprise) =>
            enterprise.enterpriseCompanyId === companyId
              ? { ...enterprise, registerStatus: 1, status: 1 }
              : enterprise
          )
        );
        alert(data.message);
      } else {
        console.error("Failed to approve company:", data.message);
      }
    } catch (error) {
      console.error("Error approving company:", error);
    }
    finally {
      setIsApproving(false); 
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentEnterprises = enterprises.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(enterprises.length / itemsPerPage);

  return (
    <>
      <div>
        <AdminSidebar />
        <div>
          <Navbar onValueChange={handleValueChange} />
          <div
            className={`${
              !valueFromNavbar ? "layout-navbar-shifted" : "layout-navbar-full"
            }`}
          >
            <div className="layout-specing">
              <div className="d-flex align-items-center justify-content-between">
                <div className="col-8  ms-3 me-5" style={{ width: "100%" }}>
                  <div className="mt-3 mb-3 ms-3">
                    <span className="fw-bold">Enterprise Tour Company</span>
                  </div>
                  <div className="table-responsive shadow rounded me-5 ms-2">
                    <Table className="table-center bg-white mb-0 ">
                      <thead>
                        <tr>
                          <th className="border-bottom p-3">Sr.no</th>
                          <th className="border-bottom p-3">Company Name</th>
                          <th className="border-bottom p-3 text-center">
                            Email
                          </th>
                          {/* <th className="border-bottom p-3 text-center">Registration Number</th> */}
                          <th className="border-bottom p-3 text-center">
                            Mobile Number
                          </th>
                          <th className="border-bottom p-3 text-center">
                            Address
                          </th>
                          <th className="border-bottom p-3 text-center">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentEnterprises.length > 0 ? (
                          currentEnterprises.map((enterprise, index) => (
                            <tr key={enterprise.enterpriseCompanyId}>
                              <td>{indexOfFirstItem + index + 1}</td>
                              <td>{enterprise.companyName}</td>
                              <td className="text-center">
                                {enterprise.email}
                              </td>
                              {/* <td className="text-center">{enterprise.registrationNumber || 'N/A'}</td> */}
                              <td className="text-center">
                                {enterprise.mobileNumber || "N/A"}
                              </td>
                              <td className="text-center">
                                {enterprise.address || "N/A"}
                              </td>
                              <td className="text-center">
                                {enterprise.registerStatus === 1 ? (
                                  <span
                                    className=" border-0  text-success"
                                    style={{ cursor: "none", fontSize: "12px", fontWeight:'700' }}
                                  >
                                    APPROVED
                                  </span>
                                ) : (
                               
                                  <Button
                                  variant="dark"
                                  style={{
                                    backgroundColor: "#343a40",
                                    borderColor: "#343a40",
                                    fontWeight: "bold",
                                    padding: "5px 20px",
                                    fontSize: "12px",
                                    borderRadius: "5px",
                                  }}
                                  onClick={() =>
                                    handleApprove(
                                      enterprise.enterpriseCompanyId
                                    )
                                  }
                                  disabled={isApproving} 
                                >
                                  {isApproving ? "Approving..." : "Pending"}
                                </Button>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="8" className="text-center">
                              No enterprises found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>

                    {/* Pagination */}
                    <Pagination className="mt-3 justify-content-center">
                      {Array.from({ length: totalPages }, (_, i) => (
                        <Pagination.Item
                          key={i + 1}
                          active={i + 1 === currentPage}
                          onClick={() => paginate(i + 1)}
                        >
                          {i + 1}
                        </Pagination.Item>
                      ))}
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EnterpriseCompanyTour;
