import React, { useEffect, useState } from "react";
import Navbar from "../common/navbar";
import Sidebar from "../sidebar/sidebar";
import AdminSidebar from "./adminSidebar";
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
  const BaseUrl = process.env.REACT_APP_EUREKA_SERVER_ENDPOINT;

  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [valueFromNavbar, setValueFromNavbar] = useState(false);

  //   const [stats, setStats] = useState({
  //     totalBookings: 0,
  //     totalTours: 0,
  //     countScheduleTours: 0,
  //   });

  const handleValueChange = (value) => {
    setValueFromNavbar(value);
  };

  // Fetch the count from the API when the component mounts
  //   useEffect(() => {
  //     fetchStats();
  //   }, []);

  //   const fetchStats = async () => {
  //     try {
  //       const response = await fetch(`${BaseUrl}/rest/auth/count`);
  //       if (!response.ok) throw new Error("Network response was not ok");
  //       const data = await response.json();
  //       localStorage.setItem("token", data.token);
  //       setStats({
  //         totalBookings: data.totalBookings,
  //         totalTours: data.totalTours,
  //         countScheduleTours: data.countScheduleTours,
  //       });
  //     } catch (error) {
  //       console.error("Error fetching stats:", error);
  //     }
  //   };

  return (
    <>
      <div>
        <div>
          <AdminSidebar />
        </div>
        <div>
          <Navbar onValueChange={handleValueChange} />
          <div
            className={`${
              !valueFromNavbar ? "layout-navbar-shifted" : "layout-navbar-full"
            }`}
          >
            <div className="layout-specing">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  {/* <h6 className="text-muted mb-1">Welcome back,</h6>
                    <h5 className="mb-0">Dashboard</h5> */}
                </div>

                {/* <div className="mb-0 position-relative">
                    <select className="form-select form-control" id="dailychart">
                        <option selected="">This Month</option>
                        <option value="aug">August</option>
                        <option value="jul">July</option>
                        <option value="jun">June</option>
                    </select>
                </div> */}
              </div>

              <div className="row ms-2 me-2">
                <div className="col mt-4">
                  <span className="features feature-primary d-flex justify-content-between align-items-center rounded shadow p-3">
                    <div className="d-flex align-items-center">
                      <div className="icon text-center rounded-pill">
                        <i className="mdi mdi-home fs-4 mb-0"></i>
                      </div>
                      <div className="flex-1 ms-3">
                        <h6 className="mb-0 text-muted">
                          Enterprise Tour Company
                        </h6>
                        {/* <p className="fs-5 text-dark fw-bold mb-0">
                          {stats.totalEnterpriseTourCompanies}
                        </p> */}

                        {/* <p className="fs-5 text-dark fw-bold mb-0">$<span className="counter-value" data-target="48575">35214</span></p> */}
                      </div>
                    </div>

                    {/* <span className="text-success"><i className="uil uil-arrow-growth"></i> 3.84%</span> */}
                  </span>
                </div>

                <div className="col mt-4">
                  <span className="features feature-primary d-flex justify-content-between align-items-center rounded shadow p-3">
                    <div className="d-flex align-items-center">
                      <div className="icon text-center rounded-pill">
                        <i className="mdi mdi-map  fs-4 mb-0"></i>
                      </div>
                      <div className="flex-1 ms-3">
                        <h6 className="mb-0 text-muted">Tours</h6>
                        {/* <p className="fs-5 text-dark fw-bold mb-0">
                          {stats.totalTours}
                        </p> */}
                        {/* <p className="fs-5 text-dark fw-bold mb-0"><span className="counter-value" data-target="4800">3402</span></p> */}
                      </div>
                    </div>

                    {/* <span className="text-success"><i className="uil uil-arrow-growth"></i> 1.46%</span> */}
                  </span>
                </div>

                <div className="col mt-4">
                  <span className="features feature-primary d-flex justify-content-between align-items-center rounded shadow p-3">
                    <div className="d-flex align-items-center">
                      <div className="icon text-center rounded-pill">
                        <i className="mdi mdi-cog fs-4 mb-0"></i>
                      </div>
                      <div className="flex-1 ms-3">
                        <h6 className="mb-0 text-muted">Theme Settings</h6>
                        <p className="fs-5 text-dark fw-bold mb-0"></p>
                        {/* <p className="fs-5 text-dark fw-bold mb-0"><span className="counter-value" data-target="145">23</span></p> */}
                      </div>
                    </div>

                    {/* <span className="text-muted"><i className="uil uil-analysis"></i> 0.0%</span> */}
                  </span>
                </div>

                <div className="col mt-4">
                  <span className="features feature-primary d-flex justify-content-between align-items-center rounded shadow p-3">
                    <div className="d-flex align-items-center">
                      <div className="icon text-center rounded-pill">
                        <i className="mdi mdi-ticket fs-4 mb-0"></i>
                      </div>
                      <div className="flex-1 ms-3">
                        <h6 className="mb-0 text-muted">Booked Tours</h6>
                        {/* <p className="fs-5 text-dark fw-bold mb-0">
                          {stats.totalBookings}
                        </p> */}
                        {/* <p className="fs-5 text-dark fw-bold mb-0"><span className="counter-value" data-target="9">1.5</span>M</p> */}
                      </div>
                    </div>

                    {/* <span className="text-danger"><i className="uil uil-chart-down"></i> 0.5%</span> */}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
