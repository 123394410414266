import React from "react";
import { Button, Modal } from "react-bootstrap";

const TourDelete=({showDeletePopup,handleClose,tour})=>{

    return(
        <>
         <Modal show={showDeletePopup} onClick={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Tour Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">Are you want to delete tour?</div>
        </Modal.Body>
        <Button
          variant="btn btn-secondary w-25 close"
          onClick={handleClose}
        >
          Close
        </Button>
        &nbsp;
      </Modal>
      </>
    )
}

export default TourDelete;