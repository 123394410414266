import React from "react";
import { Modal, Row, Col, Button, Form } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const DeleteItinerary = ({ show, handleClose }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Itinerary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">Are you want to delete iternary?</div>
        </Modal.Body>
        <Button variant="btn btn-secondary w-25 close ms-2 mb-2" onClick={handleClose}>
          Close
        </Button>
      </Modal>


      
    </>
  );
};
export default DeleteItinerary;
