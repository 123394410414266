import React, { useState } from "react";
import { Modal, Row, Col, Button, Form } from "react-bootstrap";

const LocationForm = ({ show, handleClose, tourId }) => {
  const baseAPI =process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  
  const handleLatitudeChange = (event) => {
    setLatitude(event.target.value);
  };

  const handleLongitudeChange = (event) => {
    setLongitude(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${baseAPI}/tour/save/location/tour/${tourId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
        }),
      });
      handleClose();
    } catch (error) {
      console.error("Error saving location:", error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="fs-6">{tourId}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="latitude">
                <Form.Label>Latitude</Form.Label>
                <Form.Control
                  type="number"
                  value={latitude}
                  onChange={handleLatitudeChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="longitude">
                <Form.Label>Longitude</Form.Label>
                <Form.Control
                  type="number"
                  value={longitude}
                  onChange={handleLongitudeChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button type="submit" className="close mt-2">Submit</Button>
            </Col>
          </Row>
         </Form>
      </Modal.Body>
    </Modal>
  );
};

export default LocationForm;
