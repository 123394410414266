import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthService from './auth.service';

const PrivateRoute = ({ element: Element, ...rest }) => {
  const token = localStorage.getItem('token');
  const tokenExpiry = localStorage.getItem('tokenExpiry');

  const isTokenValid = token && tokenExpiry && Date.now() < parseInt(tokenExpiry);
  if (!isTokenValid) {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiry');
    return <Navigate to="/" />;
  }

  return <Element {...rest} />;

};

export default PrivateRoute;
