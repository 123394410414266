import { setUserData } from './actions';

// Initial state
const initialState = {
  userData: null
};

//const reducers = (state = initialState, action: { type: any; payload: any; }) => {
const reducers = (state = initialState, action) => {
  console.log(action);
  switch (action.type) {
    case "SET_USER_OBJECT":
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

export default reducers;
