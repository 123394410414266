import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/scss/style.scss';
import '@mdi/font/css/materialdesignicons.min.css';
import { useNavigate, useLocation } from 'react-router-dom';

const Sidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [user, setUser] = useState(null);


    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (userData && userData.data) {
            setUser(userData.data);
        }
    }, []);

    const handleToggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleNavigation = (e, path) => {
        e.preventDefault();
        navigate(path);
    };

    return (
        <>
            <div className={`page-wrapper ${isSidebarOpen ? 'toggled' : ''}`}>
                <div id="sidebar" className="sidebar-wrapper sidebar-dark">
                    <div className="sidebar-content" data-simplebar style={{ height: `calc(100% - 60px)` }}>
                        <div className="sidebar-brand">
                            <a href="/">
                                {user && (
                                <span className="d-block sidebar-colored ms-2">
                                    {user.firstName} {user.lastName}
                                </span>
                            )}
                            </a>
                            <button onClick={handleToggleSidebar} className="btn btn-toggle-sidebar">
                                {isSidebarOpen ? '<' : '>'}
                            </button>
                        </div>

                        <ul className="sidebar-menu">
                            <li className={location.pathname === '/vendor-dashboard' ? 'active' : ''}>
                                <a href="" onClick={(e) => handleNavigation(e, '/vendor-dashboard')}>
                                    <i className="mdi mdi-home me-3"></i>
                                    Dashboard
                                </a>
                            </li>
                            <li className={location.pathname === '/create-tour' ? 'active' : ''}>
                                <a href="" onClick={(e) => handleNavigation(e, '/create-tour')}>
                                    <i className="mdi mdi-map me-3"></i>Tour
                                </a>
                            </li>
                            <li className={location.pathname === '/scheduled-tour' ? 'active' : ''}>
                                <a href="" onClick={(e) => handleNavigation(e, '/scheduled-tour')}>
                                    <i className="mdi mdi-calendar-check me-3"></i>Scheduled Tour
                                </a>
                            </li>
                            <li className={location.pathname === '/booked-tour' ? 'active' : ''}>
                                <a href="" onClick={(e) => handleNavigation(e, '/booked-tour')}>
                                    <i className="mdi mdi-ticket me-3"></i>Booked Tour
                                </a>
                            </li>
                          
                        </ul>
                        
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidebar;
