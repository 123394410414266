import React, { useState } from "react";
import backgroundImage from "../../assets/images/florian-delee-YWdMROp8-JE-unsplash.jpg"; // Ensure the path is correct
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Modal, Button } from "react-bootstrap";
import PlacesAutocomplete from "react-places-autocomplete";

const SignUp = () => {
  const BaseUrl = process.env.REACT_APP_EUREKA_SERVER_ENDPOINT;
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    countryCode: "+91",
    mobileNumber: "",
    roleName: "ENTERPRISE_COMPANY_EXECUTIVE",
    tourCompany: "",
    companyAddress: "",
    registrationNumber: "",
  });

  const [isRegistered, setIsRegistered] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [showTerms, setShowTerms] = useState(false);

  const handleShow = () => setShowTerms(true);
  const handleClose = () => setShowTerms(false);
  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [errors, setErrors] = useState({ tourCompany: "" });
  const [companyAddressError, setCompanyAddressError] = useState("");

  const handleChange = (e) => {
    // setFormData({ ...formData, [e.target.name]: e.target.value });

    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "email") {
      setEmailError("");
      setErrorMessage("");
    }

    if (name === "mobileNumber") {
      setPhoneError("");
    }

    if (name === "companyAddress") {
      setCompanyAddressError("");
    }

    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  // const handleSelectAddress = (address) => {
  //   setFormData({ ...formData, companyAddress: address });
  // };

  const handleSelectAddress = (address) => {
    setFormData({
      ...formData,
      companyAddress: address,
    });
    setCompanyAddressError("");
  };

  const validateForm = () => {
    let isValid = true;
    setEmailError("");
    setPhoneError("");
    setCompanyAddressError("");

    setErrors({});

    const validationErrors = {};

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      setEmailError("Email is required.");
      isValid = false;
    } else if (!emailPattern.test(formData.email)) {
      setEmailError("Please enter a valid email address.");
      isValid = false;
    }

    if (!formData.mobileNumber) {
      setPhoneError("Phone number is required.");
      isValid = false;
    } else if (formData.mobileNumber.length !== 10) {
      setPhoneError("Please enter a 10-digit phone number.");
      isValid = false;
    }

    if (!formData.companyAddress.trim()) {
      setCompanyAddressError("Company address is required.");
      isValid = false;
    }

    if (!formData.tourCompany.trim()) {
      validationErrors.tourCompany = "Company name is required.";
      isValid = false;
    }

    setErrors(validationErrors);

    return isValid;
  };

  const handlePhoneChange = (value, data) => {
    setFormData({
      ...formData,
      countryCode: `+${data.dialCode}`,
      mobileNumber: value.slice(data.dialCode.length),
    });

    setPhoneError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${BaseUrl}/rest/auth/company/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok && data.status === "success" && data.statusCode === 200) {
        setIsRegistered(true);
        setErrorMessage("");
      } else if (response.status === 409) {
        setErrorMessage(
          data.message || "Email already exists. Please use a different email."
        );
        setIsRegistered(false);
      } else {
        setErrorMessage(
          data.message || "Registration failed. Please try again."
        );
        setIsRegistered(false);
      }
    } catch (error) {
      setErrorMessage("An unexpected error occurred. Please try again.");
      setIsRegistered(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="cover-user">
        <div className="container-fluid px-0">
          <div className="row g-0 position-relative">
            <div className="col-lg-4 cover-my-30 order-2">
              <div className="cover-user-img d-lg-flex align-items-center">
                <div className="row">
                  <div className="col-12">
                    <div className="card border-0" style={{ zIndex: 1 }}>
                      <div className="card-body p-0">
                        <h4 className="font-medium-titles me-5 text-center">
                          Vendor Registration
                        </h4>
                        {!isRegistered ? (
                          <>
                            <form
                              className="login-form mt-2"
                              onSubmit={handleSubmit}
                            >
                              <div className="row">
                                {/* Form Fields */}
                                <div className="col-md-12">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Contact Person Name
                                    </label>
                                    <div className="form-icon position-relative">
                                      <i
                                        data-feather="user-check"
                                        className="fea icon-sm icons"
                                      ></i>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter your name"
                                        name="contactName"
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Company Email{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="form-icon position-relative">
                                      <i
                                        data-feather="mail"
                                        className="fea icon-sm icons"
                                      ></i>
                                      <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Enter your email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                      />
                                    </div>
                                    {emailError && (
                                      <div className="text-danger mt-1">
                                        {emailError}
                                      </div>
                                    )}
                                    {errorMessage && (
                                      <div className="text-danger mt-1">
                                        {errorMessage}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Phone Number{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <PhoneInput
                                      country={"in"}
                                      value={`${formData.countryCode}${formData.mobileNumber}`}
                                      onChange={handlePhoneChange}
                                      inputProps={{
                                        name: "mobileNumber",
                                        autoFocus: true,
                                      }}
                                      inputClass="form-control"
                                    />
                                    {phoneError && (
                                      <div className="text-danger mt-1">
                                        {phoneError}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Company Name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="form-icon position-relative">
                                      <i
                                        data-feather="user-check"
                                        className="fea icon-sm icons"
                                      ></i>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Company Name"
                                        name="tourCompany"
                                        value={formData.tourCompany}
                                        onChange={handleChange}
                                      />
                                    </div>
                                    {errors.tourCompany && (
                                      <div className="text-danger mt-1">
                                        {errors.tourCompany}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Company Address{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <PlacesAutocomplete
                                      value={formData.companyAddress}
                                      onChange={(address) =>
                                        setFormData({
                                          ...formData,
                                          companyAddress: address,
                                        })
                                      }
                                      onSelect={handleSelectAddress}
                                    >
                                      {({
                                        getInputProps,
                                        suggestions,
                                        getSuggestionItemProps,
                                        loading,
                                      }) => (
                                        <div
                                          className="autocomplete-container"
                                          style={{ position: "relative" }}
                                        >
                                          <input
                                            {...getInputProps({
                                              placeholder:
                                                "Search Company Address",
                                              className: "form-control",
                                            })}
                                          />
                                          <div
                                            className="autocomplete-dropdown"
                                            style={{
                                              position: "absolute",
                                              zIndex: 1000,
                                              backgroundColor: "#fff",
                                              border: "1px solid #ddd",
                                              borderRadius: "4px",
                                              width: "100%",
                                            }}
                                          >
                                            {loading && (
                                              <div className="autocomplete-item">
                                                Loading...
                                              </div>
                                            )}
                                            {suggestions.map((suggestion) => {
                                              const isActive =
                                                suggestion.active;
                                              const style = {
                                                backgroundColor: isActive
                                                  ? "#f1f1f1"
                                                  : "#ffffff",
                                                padding: "10px",
                                                borderBottom: "1px solid #eee",
                                                cursor: "pointer",
                                              };
                                              return (
                                                <div
                                                  {...getSuggestionItemProps(
                                                    suggestion,
                                                    {
                                                      style,
                                                    }
                                                  )}
                                                  key={suggestion.placeId}
                                                  className="autocomplete-item"
                                                >
                                                  {suggestion.description}
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      )}
                                    </PlacesAutocomplete>
                                    {companyAddressError && (
                                      <div className="text-danger mt-1">
                                        {companyAddressError}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="d-grid">
                                    <button
                                      className="btn btn-primary"
                                      disabled={loading}
                                    >
                                      {loading ? "Registering..." : "Register"}
                                    </button>
                                  </div>
                                </div>

                                <div className="mx-auto">
                                  <p className="mb-0 mt-3">
                                    <small className="text-dark me-2">
                                      Already have an account?
                                    </small>{" "}
                                    <a href="/" className="text-dark">
                                      Sign in
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </form>
                          </>
                        ) : (
                          <div className="text-center mt-4">
                            <h5>Registration Successful</h5>
                            <p>
                              Admin will receive your email and get back to you
                              shortly.
                            </p>
                            <button
                              className="btn btn-primary mt-3"
                              onClick={() => navigate("/")}
                            >
                              Sign In
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-8 offset-lg-4 padding-less img order-1"
              data-jarallax='{"speed": 0.5}'
            >
              <img
                src={backgroundImage}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </div>

            <Modal show={showTerms} onHide={handleClose} centered size="lg">
              <Modal.Header closeButton className="modal-header-fixed">
                <Modal.Title>Terms & Conditions</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="container ">
                  <p>
                    (By participating in 360 Explorer's (360 Explorer Inc./ 360
                    Explorer LLP / EUrekaTrip) Trek/Expeditions, you agree to
                    the following Terms and Conditions)
                  </p>
                  <p>
                    You must accept these terms and conditions to join a
                    Trekking/Tours/Expedition with 360 Explorers LLP.
                  </p>

                  <h6>ELIGIBILITY:</h6>
                  <p>
                    Participants must be in suitable physical and mental
                    condition at the time of their Trekking departure date.
                    Participants must fully understand the potential risks
                    involved in the activity undertaken. Participants under the
                    age of 18 must have absolute consent from a legal guardian
                    witnessed by at least two independent parties.
                  </p>

                  <h6>1) Modes of Payment:</h6>
                  <p>Online | Cash (With Taxes)</p>
                  <p>
                    Any cancellation request made should be in writing to the
                    Email address. Participants accept that all cancellation
                    settlements will be managed by 360 Explorers LLP.
                  </p>

                  <h6>2) TRANSFER AND CHANGES</h6>
                  <p>
                    If you wish to change the date of a Trekking or get
                    transferred into another Trek/Expedition/Tour you have
                    booked with us, please inform us and make changes no later
                    than 10 days before the commencement of the Trekking in
                    Maharashtra, 30 Days before the International Expedition. We
                    are not responsible for any Airline Booking.
                  </p>

                  <h6>3) RISKS AND SAFETY</h6>
                  <p>
                    All participants taking part in any 360 Explorer LLP
                    Trekking Events acknowledge and fully understand that these
                    Trekking activities involve elements of risk depending on
                    the nature of the challenge. While 360 Explorers aim to
                    safeguard participants' safety and health, we cannot be held
                    responsible for damage, illness, or injury sustained during
                    or as a result of your participation. You thus will take
                    part entirely at your own risk and agree to indemnify us and
                    our partners, the charity, our employees, agents,
                    sub-contractors, and suppliers against claims for any loss
                    or damage to personal property or for loss or consequential
                    losses or claims through your participation in this
                    challenge arising from your actions. You are responsible for
                    bringing your equipment clearly mentioned in the kit list of
                    the particular challenge's itinerary. We are not responsible
                    in any way if you are not able to arrange adequate clothing
                    and equipment, otherwise, you will not be allowed to
                    continue on a Trek. All participants must wear safety
                    clothing or equipment as mentioned by guides or may be
                    required in the country concerned or under the rules and
                    regulations of any local service provider for any activity
                    undertaken by you. Trek guides and leaders at any stage have
                    the right to stop the participant to continue on their
                    respective Trek if they find that the participant is not
                    adequately fit, healthy, adequately equipped, and is
                    affecting the Trek safety and progress.
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer className="footer-fixed">
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
