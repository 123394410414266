import React, { useState, useEffect } from "react";
import { Button, Form, Container, Row, Col, Card } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import he from "he";
import { toast } from "react-toastify";
import PlacesAutocomplete, { geocodeByAddress,getLatLng,} from "react-places-autocomplete";
import { useSelector } from "react-redux";
import { PlusCircle, DashCircle } from "react-bootstrap-icons";
import cc from "currency-codes";
import "../../componets/tour/tours.css";

const EditTour = ({tour,handleUpdateTour,handleCancelEdit,onCloseAndRefresh,}) => {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;

  const { token } = useSelector((state) => state);
  const [address, setAddress] = useState("");
  const [cancellationPolicies, setCancellationPolicies] = useState([]);
  const [themes, setThemes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [tagIdentifiers, setTagIdentifiers] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [selections, setSelections] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [personalizeCat, setPersonalizeCat] = useState();
  const [personalizeCatQue, setPersonalizeCatQue] = useState();
  const [personalizeCatQueAns, setPersonalizeCatQueAns] = useState();
  const [selectedPersQueAns, setSelectedPersQueAns] = useState([]);
  const [personlizedSubCategory, setPersonlizedSubCategory] = useState([]);
  const [cancellationPolicyId, setcancellationPolicyId] = useState({});
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
  const [errors, setErrors] = useState({});

  const [tourData, setTourData] = useState({
    title: "",
    shortDescription: "",
    description: "",
    highlights: "",
    included: "",
    notIncluded: "",
    additionalInformation: "",
    cancelation: "",
    cancellationPolicyId: "",
    capacity: "",
    maxParticipants: "",
    featureTour: 0,
    specialTour: 0,
    splTourToBMM: 0,
    personlizedSubCategory: [],
    question: "",
    themeId: "",
    categoryId: "",
    subCategoryId: "",
    latitude: "",
    longitude: "",
    address: "",
    price: 0,
    currency: "",
    placeId: "",
  });

  const validate = () => {
    const newErrors = {};

    if (!tourData.title.trim()) {
      newErrors.title = "Title is required";
    }
    if (!tourData.shortDescription.trim()) {
      newErrors.shortDescription = "Short Description is required";
    }
    if (!tourData.description.trim()) {
      newErrors.description = "Description is required";
    }
    if (!tourData.highlights.trim()) {
      newErrors.highlights = "Highlights are required";
    }
    if (!tourData.address.trim()) {
      newErrors.address = "Search Location is required";
    }
    if (!tourData.maxParticipants) {
      newErrors.maxParticipants = "Max Participants is required";
    }
    if (!tourData.capacity) {
      newErrors.capacity = "Max Capacity is required";
    }
    if (tourData.maxParticipants > tourData.capacity) {
      newErrors.maxParticipants = "Max Participants cannot be greater than Capacity";
    }
    if (tourData.capacity < tourData.maxParticipants) {
      newErrors.capacity = "Capacity cannot be less than Max Participants";
    }
    if (!tourData.cancelation.trim()) {
      newErrors.cancelation = "Cancellation is required";
    }
    if (!cancellationPolicyId) {
      newErrors.cancellationPolicyId = "Cancellation Policy is required";
    }
    if (selections.length === 0) {
      newErrors.theme = "At least one theme is required";
    }

    return newErrors;
  };

  useEffect(() => {
    const options = cc.codes().map((code) => ({
      value: code,
      label: `${code}`,
    }));
    setCurrencyOptions(options);
  }, []);

  useEffect(() => {
    fetchCancellationPolicies();
    fetchPersonalizedSubCategories();

    if (window.google && window.google.maps) {
      setIsGoogleMapsLoaded(true);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BaseAPI}/tour/${tour.tourId}/personalized-cat-details`
        );
        const data = await response.json();
        if (data.statusCode === 200 && data.data) {
          setSelectedPersQueAns(data.data);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    fetchData();
  }, [tour.tourId]);

  useEffect(() => {
    fetchThemes();

    if (tour) {
      console.log("tour===========12354>>",tour);

      setTourData({
        ...tour,
        address: tour.location?.address || "",
        latitude: tour.location?.latitude || "",
        longitude: tour.location?.longitude || "",
        placeId: tour.location?.placeId || "",
        currency: tour.currency || "",
        questionsData: questionsData.length > 0 ? questionsData : [],
      });
      setSelections(tour.tourTheme);
      setAddress(tour.location?.address || "");
    }
  }, [tour, personlizedSubCategory, questionsData, tagIdentifiers]);

  useEffect(() => {
    if (tourData.themeId) {
      fetchCategoriesByTheme(tourData.themeId);
    } else {
      setCategories([]);
    }
  }, [tourData.themeId]);

  useEffect(() => {
    if (tourData.categoryId) {
      fetchSubCategories(tourData.categoryId);
    } else {
      setSubCategories([]);
    }
  }, [tourData.categoryId]);

  const fetchCategoriesByTheme = async (themeId) => {
    try {
      const response = await fetch(`${BaseAPI}/tour/category/theme/${themeId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCategories(data.data);
    } catch (error) {
      console.error("Error fetching categories by theme:", error);
    }
  };

  const fetchSubCategories = async (categoryId) => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/subcategory/category/${categoryId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setSubCategories(data.data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const getQuestion = async (name) => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/questions/personalized-category/${name}`
      );
      if (!response.ok) {
        throw new Error(`Error fetching all tours: ${response.statusText}`);
      }
      const data = await response.json();

      setQuestionsData(data.data.question);
    } catch (error) {
      console.error("Error fetching all tours:", error);
    }
  };

  const gettagIdentifiersByQuestionId = async (questionId) => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/tagIdentifiers/personalizedCategory/${questionId}`
      );
      if (!response.ok) {
        throw new Error("Error fetching all tours: ${response.statusText}");
      }
      const data = await response.json();
      setTagIdentifiers(data.data);
    } catch (error) {
      console.error("Error fetching all tours:", error);
    }
  };

  const handleCancellationChange = (e) => {
    setcancellationPolicyId(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setTourData((prevData) => ({
      ...prevData,
      [name]: ["featureTour", "specialTour", "splTourToBMM"].includes(name)
        ? parseInt(value)
        : value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    if (name === "themeId" && value) {
      setErrors((prevErrors) => {
        const { theme, ...restErrors } = prevErrors;
        return restErrors;
      });
    }

    if (name === "themeId") {
      if (value) {
        fetchCategoriesByTheme(value);
      } else {
        setCategories([]);
        setSubCategories([]);
      }
    } else if (name === "categoryId") {
      if (value) {
        fetchSubCategories(value);
      } else {
        setSubCategories([]);
      }
    }

    if (name === "personalizeCat") {
      let personalCatObject = personlizedSubCategory.find(
        (item) => item.personalizedCategoryId === value
      );
      getQuestion(personalCatObject?.name);
      setPersonalizeCat(value);
    }

    if (name === "personalizeCatQue") {
      console.log("question", name);
      gettagIdentifiersByQuestionId(value);
      setPersonalizeCatQue(value);
    }
    if (name == "personalizeCatQueAns") {
      setPersonalizeCatQueAns(value);
    }
  };

  const handleTextEditorChange = (value, name) => {
    const sanitizeContent = (content) => {
      return content.replace(/<p>(\s|<br>)*<\/p>/g, "").trim();
    };

    const sanitizedValue = sanitizeContent(value);

    const encodedHtml = he.encode(sanitizedValue, {
      useNamedReferences: true,
    });

    console.log("Sanitized Value:", sanitizedValue);

    setTourData((prevData) => ({
      ...prevData,
      [name]: encodedHtml,
    }));
  };

  const handleAddressChange = (value) => {
    setAddress(value);

    setTourData((prevData) => {
      const updatedTourData = {
        ...prevData,
        address: value,
      };

      if (!value) {
        updatedTourData.latitude = "";
        updatedTourData.longitude = "";

        setErrors((prevErrors) => ({
          ...prevErrors,
          address: "Location is required",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          address: "",
        }));
      }

      return updatedTourData;
    });
  };

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);

    setTourData((prevData) => ({
      ...prevData,
      address: value,
      latitude: latLng.lat,
      longitude: latLng.lng,
      placeId: results[0].place_id,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      address: "",
    }));
  };

  const fetchCancellationPolicies = async () => {
    try {
      const response = await fetch(`${BaseAPI}/tour/all/cancellationPolicy`);
      const data = await response.json();
      setCancellationPolicies(data.data);
      if (tour && tour.cancellationPolicy) {
        const selectedPolicy = data.data.find(
          (policy) => policy.policyName === tour.cancellationPolicy
        );

        if (selectedPolicy) {
          setTourData((prevData) => ({
            ...prevData,
            cancellationPolicyId: selectedPolicy.cancellationPolicyId,
          }));
        }
      }
      console.log("data.======>", data.data);
    } catch (error) {
      console.error("Error fetching cancellation policies:", error);
    }
  };

  const fetchThemes = async () => {
    try {
      const response = await fetch(`${BaseAPI}/tour/all/themes`);
      const data = await response.json();
      setThemes(data.data);
    } catch (error) {
      console.error("Error fetching themes:", error);
    }
  };

  const fetchPersonalizedSubCategories = async () => {
    try {
      const response = await fetch(`${BaseAPI}/tour/all/personalized-category`);
      const data = await response.json();
      setPersonlizedSubCategory(data.data);
    } catch (error) {
      console.error("Error fetching personalized subcategories:", error);
    }
  };

  const handleAddThemeCategorySubcategory = () => {
    const selectedTheme = themes?.find(
      (theme) => theme?.themeId === tourData?.themeId
    );
    const selectedCategory = categories?.find(
      (cat) => cat?.categoryId === tourData?.categoryId
    );
    const selectedSubCategory = subCategories?.find(
      (sub) => sub?.subCategoryId === tourData?.subCategoryId
    );
  
    const newSelection = {
      theme: selectedTheme,
      category: selectedCategory,
      subCategory: selectedSubCategory,
    };
  
    const newErrors = {};
  
    if (selectedTheme?.themeName === "Adventure") {
      if (!selectedCategory && !selectedSubCategory) {
        newErrors.categoryId = "Category is required.";
      } else if (selectedCategory && !selectedSubCategory) {
        newErrors.subCategoryId = "Subcategory is required.";
      }
    }
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      console.error("Validation Errors:", newErrors);
      return;
    }
  
    if (selectedTheme && Object.keys(newSelection).length !== 0) {
      const isDuplicate = selections.some(
        (selection) =>
          selection.theme?.themeId === newSelection.theme?.themeId &&
          selection.category?.categoryId === newSelection.category?.categoryId &&
          selection.subCategory?.subCategoryId === newSelection.subCategory?.subCategoryId
      );
  
      if (isDuplicate) {
        toast.warn("This combination is already added.");
        return;
      }
  
      const updatedSelections = [...selections, newSelection];
      setSelections(updatedSelections);
  
      setTourData((prevData) => ({
        ...prevData,
        themes: updatedSelections.map((selection) => ({
          themeId: selection.theme?.themeId,
          categoryId: selection.category?.categoryId || null,
          subCategoryId: selection.subCategory?.subCategoryId || null,
        })),
      }));
  
      setTourData((prevData) => ({
        ...prevData,
        themeId: "",
        categoryId: "",
        subCategoryId: "",
      }));
  
      setErrors((prevErrors) => {
        const { categoryId, subCategoryId, ...restErrors } = prevErrors;
        return restErrors;
      });
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        theme: "Theme is required.",
      }));
    }
  };
  

  const handleRemoveSelection = (index) => {
    setSelections((prev) => prev.filter((_, idx) => idx !== index));
  };


  const handlePerCatQueAns = () => {
    const personalCatObject = personlizedSubCategory.find(
      (item) => item.personalizedCategoryId === personalizeCat
    );
  
    const personalizeCatQueObject = questionsData.find(
      (item) => item.questionId === personalizeCatQue
    );
  
    const personalizeCatQueAnsObject = tagIdentifiers.find(
      (item) => item.objectiveId === personalizeCatQueAns
    );
  
    if (!personalCatObject || !personalizeCatQueObject || !personalizeCatQueAnsObject) {
      toast.error("Please select valid options before adding.");
      return;
    }
  
    const tmpSelectedPersQueAns = {
      personalizeCat: personalCatObject,
      personalizeCatQue: {
        id: personalizeCatQueObject.questionId,
        name: personalizeCatQueObject.question,
      },
      personalizeCatQueAns: personalizeCatQueAnsObject,
    };
  
    const isDuplicate = selectedPersQueAns.some(
      (entry) =>
        entry.personalizeCat.personalizedCategoryId ===
          tmpSelectedPersQueAns.personalizeCat.personalizedCategoryId &&
        entry.personalizeCatQue.id === tmpSelectedPersQueAns.personalizeCatQue.id &&
        entry.personalizeCatQueAns.objectiveId ===
          tmpSelectedPersQueAns.personalizeCatQueAns.objectiveId
    );
  
    if (isDuplicate) {
      toast.warn("This combination is already added.");
      return;
    }
  
    setSelectedPersQueAns((prevSelected) => [...prevSelected, tmpSelectedPersQueAns]);
    toast.success("Successfully added.");
  };
  

  const handleRemoveTagIdentifier = (index) => {
    const updatedTagIdentifiers = selectedPersQueAns.filter(
      (_, i) => i !== index
    );
    console.log("updatedTagIdentifiers========>", updatedTagIdentifiers);
    setSelectedPersQueAns(updatedTagIdentifiers);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const formErrors = validate();
    console.log(formErrors);
    if (Object.keys(formErrors).length > 0) {
      toast.warn(formErrors);

      setErrors(formErrors);
      setIsloading(false);

      return;
    }

    setErrors({});

    setIsloading(true);
// const location = {address:tourData.address}
    const updatedTourData = {
      title: tourData.title,
      shortDescription: he.decode(tourData.shortDescription),
      description: he.decode(tourData.description),
      maxParticipants: tourData.maxParticipants,
      cancelation: tourData.cancelation,
      cancellationPolicyId: tourData.cancellationPolicyId,
      highlights: he.decode(tourData.highlights),
      included: he.decode(tourData.included),
      notIncluded: he.decode(tourData.notIncluded),
      additionalInformation: he.decode(tourData.additionalInformation),
      latitude: parseFloat(tourData.latitude),
      longitude: parseFloat(tourData.longitude),
      placeId: tourData.placeId || "",
      address: tourData.address,
      price: parseFloat(tourData.price),
      currency: tourData.currency,
      capacity: tourData.capacity,
      featureTour: tourData.featureTour,
      specialTour: tourData.specialTour,
      splTourToBMM: tourData.splTourToBMM,
      // location:location,

      associateCategories: selections.map((selection) => ({
        themeId: selection?.theme?.themeId,
        categoryId: selection?.category?.categoryId,
        subCategoryId: selection?.subCategory?.subCategoryId,
      })),
      personalizedCategory: selectedPersQueAns,
    };
    console.log("tourData.address===========>", tourData.address);

    try {
      const response = await fetch(
        `${BaseAPI}/tour/update/tour/${tour.tourId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedTourData),
        }
      );

      if (!response.ok) throw new Error("Failed to update tour");

      const updatedData = await response.json();
      toast.success("Tour updated successfully!");
      handleUpdateTour(updatedData);
      onCloseAndRefresh();
    } catch (error) {
      // toast.error("Error updating tour");
      console.error(error);
    }
  };

  return (
    <Container className=" ms-1 font-common">
      <Row className="justify-content-center">
        <Col lg={11} md={10}>
          <div className="mb-2 ">
            <span className="fs-4 mb-4 font-common">Edit Tour</span>
          </div>
          <Card>
            <Card.Body className="font-common">
              <Form onSubmit={handleUpdate}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Title <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter tour name"
                    name="title"
                    value={tourData.title}
                    onChange={handleInputChange}
                    isInvalid={!!errors.title}
                  />
                  {errors.title && (
                    <div style={{ color: "red", fontSize: "14px" }}>
                      {errors.title}
                    </div>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Short Description
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <ReactQuill
                    as="textarea"
                    rows={3}
                    className="h-50"
                    name="shortDescription"
                    value={he.decode(tourData.shortDescription)}
                    onChange={(value) =>
                      handleTextEditorChange(value, "shortDescription")
                    }
                    isInvalid={!!errors.shortDescription}
                  />
                  {errors.shortDescription && (
                    <div style={{ color: "red", fontSize: "14px" }}>
                      {errors.shortDescription}
                    </div>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Description
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <ReactQuill
                    as="textarea"
                    rows={3}
                    name="description"
                    value={he.decode(tourData.description)}
                    onChange={(value) =>
                      handleTextEditorChange(value, "description")
                    }
                    isInvalid={!!errors.description}
                  />
                  {errors.description && (
                    <div style={{ color: "red", fontSize: "14px" }}>
                      {errors.description}
                    </div>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Highlights
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <ReactQuill
                    name="highlights"
                    theme="snow"
                    value={he.decode(tourData.highlights)}
                    onChange={(value) =>
                      handleTextEditorChange(value, "highlights")
                    }
                    isInvalid={!!errors.highlights}
                  />
                  {errors.highlights && (
                    <div style={{ color: "red", fontSize: "14px" }}>
                      {errors.highlights}
                    </div>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>What's Included</Form.Label>
                  <ReactQuill
                    as="textarea"
                    type="textarea"
                    name="included"
                    value={he.decode(tourData.included)}
                    onChange={(value) =>
                      handleTextEditorChange(value, "included")
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>What's Not Included</Form.Label>
                  <ReactQuill
                    type="textarea"
                    as="textarea"
                    name="notIncluded"
                    value={he.decode(tourData.notIncluded)}
                    onChange={(value) =>
                      handleTextEditorChange(value, "notIncluded")
                    }
                  />
                </Form.Group>

                <Form.Group className="mt-2 position-relative">
                  <Form.Label>
                    Search Location <span className="text-danger"> *</span>
                  </Form.Label>
                  {isGoogleMapsLoaded ? (
                    <PlacesAutocomplete
                      value={address}
                      onChange={handleAddressChange}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div
                          className="autocomplete-container"
                          style={{ position: "relative" }}
                        >
                          <input
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className: "form-control",
                            })}
                          />
                          <div
                            className="autocomplete-dropdown"
                            style={{
                              position: "absolute",
                              zIndex: 1000,
                              backgroundColor: "#fff",
                              border: "1px solid #ddd",
                              borderRadius: "4px",
                              width: "100%",
                            }}
                          >
                            {loading && (
                              <div className="autocomplete-item">
                                Loading...
                              </div>
                            )}
                            {suggestions.map((suggestion) => {
                              const isActive = suggestion.active;
                              const style = {
                                backgroundColor: isActive
                                  ? "#f1f1f1"
                                  : "#ffffff",
                                padding: "10px",
                                borderBottom: "1px solid #eee",
                                cursor: "pointer",
                              };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                  key={suggestion.placeId}
                                  className="autocomplete-item"
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  ) : (
                    <div>Loading Google Maps...</div>
                  )}
                  {errors.address && (
                    <div style={{ color: "red", fontSize: "14px" }}>
                      {errors.address}
                    </div>
                  )}
                </Form.Group>

                <Row className="mb-3 mt-4">
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Latitude</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Latitude"
                        name="latitude"
                        value={tourData.latitude}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Longitude</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Longitude"
                        name="longitude"
                        value={tourData.longitude}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3">
                  <Form.Label>Additional Information</Form.Label>
                  <ReactQuill
                    value={he.decode(tourData.additionalInformation)}
                    onChange={(value) =>
                      handleTextEditorChange(value, "additionalInformation")
                    }
                  />
                </Form.Group>

                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>
                        Max Participants{" "}
                        <span style={{ color: "red" }}> *</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter tour maxParticipants"
                        name="maxParticipants"
                        value={tourData.maxParticipants}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value >= 0 || value === "") {
                            handleInputChange(e);
                          }
                        }}
                        isInvalid={!!errors.maxParticipants}
                      />
                      {errors.maxParticipants && (
                        <div style={{ color: "red", fontSize: "14px" }}>
                          {errors.maxParticipants}
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Tour Max Capacity{" "}
                        <span style={{ color: "red" }}> *</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="capacity"
                        placeholder="Enter tour max capacity"
                        value={tourData.capacity}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value >= 0 || value === "") {
                            handleInputChange(e);
                          }
                        }}
                        isInvalid={!!errors.capacity}
                      />
                      {errors.capacity && (
                        <div style={{ color: "red", fontSize: "14px" }}>
                          {errors.capacity}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md="4">
                    <Form.Group>
                      <Form.Label>Feature Tour</Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="radio"
                          label="Yes"
                          name="featureTour"
                          value={1}
                          checked={tourData.featureTour === 1}
                          onChange={handleInputChange}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label="No"
                          name="featureTour"
                          value={0}
                          checked={tourData.featureTour === 0}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md="4">
                    <Form.Group>
                      <Form.Label>Special Tour</Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="radio"
                          label="Yes"
                          name="specialTour"
                          value={1}
                          checked={tourData.specialTour === 1}
                          onChange={handleInputChange}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label="No"
                          name="specialTour"
                          value={0}
                          checked={tourData.specialTour === 0}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md="4">
                    <Form.Group>
                      <Form.Label>Special BMM Tour</Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="radio"
                          label="Yes"
                          name="splTourToBMM"
                          value={1}
                          checked={tourData.splTourToBMM === 1}
                          onChange={handleInputChange}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label="No"
                          name="splTourToBMM"
                          value={0}
                          checked={tourData.splTourToBMM === 0}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>
                        Cancellation <span style={{ color: "red" }}> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="cancelation"
                        placeholder="Enter tour cancellation Days"
                        value={tourData.cancelation}
                        onChange={handleInputChange}
                        isInvalid={!!errors.cancelation}
                      />
                      {errors.cancelation && (
                        <div style={{ color: "red", fontSize: "14px" }}>
                          {errors.cancelation}
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>
                        Cancellation Policy{" "}
                        <span style={{ color: "red" }}> *</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="cancellationPolicyId"
                        value={tourData.cancellationPolicyId}
                        onChange={handleCancellationChange}
                      >
                        <option value="">Select Cancellation Policy</option>
                        {cancellationPolicies.map((policy) => (
                          <option
                            key={policy.cancellationPolicyId}
                            value={policy.cancellationPolicyId}
                          >
                            {policy.policyName}
                          </option>
                        ))}
                      </Form.Control>
                      {errors.cancellationPolicyId && (
                        <div style={{ color: "red", fontSize: "14px" }}>
                          {errors.cancellationPolicyId}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <hr />

                <Row className="pt-2">
                  <div className="row">
                    <Col md={4}>
                      <div className="select-wrapper">
                        <Form.Label className="form-label">
                          Select Theme <span style={{ color: "red" }}> *</span>
                        </Form.Label>

                        <Form.Control
                          as="select"
                          name="themeId"
                          onChange={handleInputChange}
                        >
                          <option value="" selected>
                            Select a Theme
                          </option>
                          {themes.map((theme) => (
                            <option key={theme.themeId} value={theme.themeId}>
                              {theme.themeName}
                            </option>
                          ))}
                        </Form.Control>

                        {errors.theme && (
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {errors.theme}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="select-wrapper">
                        <Form.Label className="form-label">
                          Category <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="categoryId"
                          onChange={handleInputChange}
                        >
                          <option value="" selected>
                            Select a Category
                          </option>
                          {categories &&
                            Array.isArray(categories) &&
                            categories.map((category) => (
                              <option
                                key={category.categoryId}
                                value={category.categoryId}
                              >
                                {category.categoryName}
                              </option>
                            ))}
                        </Form.Control>
                        {errors.categoryId && (
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {errors.categoryId}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="select-wrapper font-common">
                        <Form.Label className="form-label font-medium-titles">
                          Subcategory <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <div className="d-flex align-items-center">
                          <Form.Control
                            as="select"
                            name="subCategoryId"
                            onChange={handleInputChange}
                          >
                            <option value="" selected>
                              Select a Subcategory
                            </option>
                            {subCategories &&
                              Array.isArray(subCategories) &&
                              subCategories.map((subCategory) => (
                                <option
                                  key={subCategory.subCategoryId}
                                  value={subCategory.subCategoryId}
                                >
                                  {subCategory.subCategoryName}
                                </option>
                              ))}
                          </Form.Control>
                          <Button
                            onClick={handleAddThemeCategorySubcategory}
                            className=" ms-2 theme-btn"
                          >
                            <PlusCircle style={{ fontSize: "15px" }} />

                            <span className=" ms-2">Add</span>
                          </Button>
                        </div>
                        {errors.subCategoryId && (
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {errors.subCategoryId}
                          </div>
                        )}
                      </div>
                    </Col>
                  </div>
                </Row>

                <Row>
                  {selections.length > 0 &&
                    selections.map((entry, index) => (
                      <li
                        key={index}
                        className="d-flex align-items-center mb-1 p-1 rounded ms-3 me-3"
                        style={{ marginBottom: "15px" }}
                      >
                        <div className="d-flex justify-content-between w-100">
                          <div className=" font-common">
                            {entry.theme?.themeName}
                          </div>
                          <div className="font-common">
                            {entry.category?.categoryName}
                          </div>
                          <div className="text-primary font-common me-3">
                            {entry.subCategory?.subCategoryName}
                          </div>
                        </div>
                        <DashCircle
                          style={{
                            cursor: "pointer",
                            color: "#dc3545",
                            fontSize: "15px",
                          }}
                          onClick={() => handleRemoveSelection(index)}
                          className="me-5"
                        />
                      </li>
                    ))}
                </Row>
                <hr />
                <Row className="mb-3 mt-3 pt-2 ">
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>
                        Select Personalized Categories{" "}
                      </Form.Label>
                      <Form.Select
                        name="personalizeCat"
                        value={personalizeCat}
                        onChange={(e) => {
                          handleInputChange(e);
                          getQuestion(e.target.value);
                        }}
                      >
                        <option value="">Select personalized category</option>
                        {Array.isArray(personlizedSubCategory) &&
                          personlizedSubCategory.map((subCat) => (
                            <option
                              key={subCat.personalizedCategoryId}
                              value={subCat.personalizedCategoryId}
                            >
                              {subCat.name}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Select Question</Form.Label>
                      <Form.Select
                        name="personalizeCatQue"
                        value={personalizeCatQue}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Question</option>
                        {Array.isArray(questionsData) &&
                          questionsData.map((question) => (
                            <option
                              key={question.questionId}
                              value={question.questionId}
                            >
                              {question.question}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Select Tag Identifier </Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Select
                          name="personalizeCatQueAns"
                          value={personalizeCatQueAns}
                          onChange={handleInputChange}
                          className="flex-grow-1"
                        >
                          <option value="">Select Tag Identifier</option>
                          {Array.isArray(tagIdentifiers) &&
                            tagIdentifiers.map((tag) => (
                              <option
                                key={tag.objectiveId}
                                value={tag.objectiveId}
                              >
                                {tag.tagIdentifier}
                              </option>
                            ))}
                        </Form.Select>

                        <Button
                          onClick={handlePerCatQueAns}
                          className=" ms-2 theme-btn"
                        >
                          <PlusCircle style={{ fontSize: "15px" }} />

                          <span className=" ms-2">Add</span>
                        </Button>
                      </div>
                    </Form.Group>
                  </Col>

                  <Row>
                    {selectedPersQueAns.map((entry, index) => (
                      <li
                        key={index}
                        className="d-flex align-items-center mb-1 p-1 rounded font-common"
                        style={{ marginBottom: "15px" }}
                      >
                        <div className="d-flex justify-content-between w-100 ms-3 mt-2">
                          <div className="">{entry.personalizeCat?.name}</div>
                          <div className="">
                            {entry.personalizeCatQue?.name}
                          </div>
                          <div className="text-primary">
                            {entry.personalizeCatQueAns?.tagIdentifier}
                          </div>
                        </div>
                        <DashCircle
                          style={{
                            cursor: "pointer",
                            color: "#dc3545",
                            fontSize: "15px",
                            marginLeft: "15px",
                          }}
                          onClick={() => handleRemoveTagIdentifier(index)}
                        />
                      </li>
                    ))}
                  </Row>
                </Row>

                <div className="d-flex justify-content-center mt-4 mb-2">
                  <Button type="submit" variant="primary" className="me-2">
                    Update Tour
                  </Button>
                  <Button variant="secondary" onClick={handleCancelEdit}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EditTour;
