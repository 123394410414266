import React from "react";
import { useState, useEffect } from "react";
import { Form, Button, Table, Container, Col, Row, Modal } from "react-bootstrap";

function AdminToursEdit({ showEditPopup, handleClose, tour }) {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT

  const [themes, setThemes] = useState([]);
  const [themeId, setthemeId] = useState();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [formData, setFormData] = useState({
    title: tour?.title || "",
    maxParticipants: tour?.maxParticipants || "",
    description: tour?.description || "",
    themeId:tour?.themeId || "",
    categoryId:tour?.categoryId || "",
    subCategoryId:tour?.subCategoryId || "",
    duration: tour?.duration || "",
    durationTime: tour?.durationTime || "",
    price: tour?.price || "",
    images: [],
    themes: []
  });
  useEffect(() => {
    fetchTheme();
    fetchCategoriesByTheme(tour?.themeId)
    fetchSubCategories(tour?.categoryId)
 }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const themeId = e.target.value
    fetchCategoriesByTheme(themeId)
      setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCategory = (e) => {
    const { name, value } = e.target;
    const categoryId = e.target.value
    setSubCategories([])
    setFormData((prevData) => ({
      ...prevData,
      categoryId: categoryId,
    }));
   fetchSubCategories(categoryId)
  
  };
  
  const handleSubCategory = (e) => {
    const subCategoryId = e.target.value
    setFormData((prevData) => ({
      ...prevData,
      subCategoryId: subCategoryId,
    }));
  };

  const fetchCategoriesByTheme = async (themeId) => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/category/theme/${themeId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCategories(data.data);
    } catch (error) {
      console.error("Error fetching categories by theme:", error);
    }
  };

  const handleUpdate = async (e) => {
    const editTourData ={
      "tourId":tour.tourId ,
      "title": formData.title,
      "description":formData.description,
      "maxParticipants": formData.maxParticipants,
      "duration": formData.duration,
      "durationTime": formData.durationTime,
      "price":formData.price,
      "associateCategories": [
        {
           "themeId": formData.themeId,
          "categoryId": formData.categoryId,
          "subCategoryId": formData.subCategoryId
        }
      ]
    }    
    e.preventDefault();
    try {
      if (!tour || !tour.tourId) {
        throw new Error("Tour data is missing or invalid");
      }

      const response = await fetch(
        `${BaseAPI}/tour/update/tour/${tour.tourId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editTourData),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update tour");
      }
      const responseData = await response.json();
      handleClose();
    } catch (error) {
      console.error("Error updating tour:", error);
    }
  };

  const fetchSubCategories = async (categoryId) => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/subcategory/category/${categoryId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setSubCategories(data.data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const fetchTheme = async () => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/all/themes`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setThemes(data.data);

    } catch (error) {

      console.error("Error fetching themes:", error);
    }
  };

  return (
    <>
      <Modal className="modal-lg mt-5" show={showEditPopup} tour={tour} >
        <div className="bg-light">
          <Modal.Header closeButton onClick={handleClose}>
            <Modal.Title>Tour Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formTourName">
                  <Form.Label>Tour Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter tour name" name="title"
                    value={formData.title}
                    onChange={handleInputChange} />
                </Form.Group>
                <Form.Group as={Col} controlId="formMaxParticipants">
                  <Form.Label>Max Participants</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter max participants"
                    name="maxParticipants"
                    value={formData.maxParticipants}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formprice">
                  <Form.Label>Price</Form.Label>
                  <Form.Control type="text" placeholder="Enter price" name="price"
                    value={formData.price}
                    onChange={handleInputChange} />
                </Form.Group>
                <Form.Group as={Col} controlId="formTheme">
                  <Form.Label>Select Theme</Form.Label>
                  <Form.Control
                    as="select"
                    name="themeId"
                    value={formData.themeId}
                    className="Customs"
                    onChange={handleInputChange}
                  >
                    <option value="">Select a Theme</option>
                    {themes &&
                      Array.isArray(themes) &&
                      themes.map((theme) => (
                        <option
                          key={theme?.themeId}
                          value={theme?.themeId}
                        >
                          {theme?.themeName}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Row>
              <Row className="mt-3">
                <Form.Group as={Col} controlId="formCategory">
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    as="select"
                    name="categoryId"
                    value={formData.categoryId}
                    onChange={handleCategory}
                    className="Customs"
                  >
                    <option value="">Select a Category</option>
                    {categories &&
                      Array.isArray(categories) &&
                      categories.map((category) => (
                        <option
                          key={category.categoryId}
                          value={category.categoryId}
                        >
                          {category.categoryName}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} controlId="formSubcategory">
                  <Form.Label>Subcategory</Form.Label>
                  <Form.Control
                    as="select"
                    name="subCategoryId"
                    value={formData.subCategoryId}
                    onChange={handleSubCategory}
                    className="Customs"
                  >
                    <option value="">Select a Subcategory</option>
                    {subCategories &&
                      Array.isArray(subCategories) &&
                      subCategories.map((subCategory) => (
                        <option
                          key={subCategory.subCategoryId}
                          value={subCategory.subCategoryId}
                        >
                          {subCategory.subCategoryName}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Row>
              <Row className="mt-4">
              <Form.Group as={Col} controlId="durationdays">
                  <Form.Label>Duration</Form.Label>
                  <Form.Control type="text" placeholder="Enter tour duration" name="description"
                    value={formData.duration}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="durationdays">
                  <Form.Label>Duration Time</Form.Label>
                  <Form.Control type="text" placeholder="Enter tour days" name="description"
                    value={formData.durationTime}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                </Row>
                <Row>
                <Form.Group as={Col} controlId="formTourDescription"className="mt-3">
                  <Form.Label>Tour Description</Form.Label>
                  <Form.Control as="textarea" placeholder="Enter tour description" name="description" rows={4}
                    value={formData.description}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                </Row>
              <div className="d-flex justify-content-end mt-4">
                <Button type="submit" variant="primary" onClick={handleUpdate}>Update</Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default AdminToursEdit;
