const BaseUrl = process.env.REACT_APP_EUREKA_SERVER_ENDPOINT;

const Login = async (username, password) => {
    const response = await fetch(`${BaseUrl}/rest/auth/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
    });
    return response;
};

const Logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("userData");
};

const getAuthToken = () => {
  return localStorage.getItem("token");
};

const isAuthenticated = () => {
  return !!getAuthToken();
};

const AuthService = {
  Login,
  Logout,
  getAuthToken,
  isAuthenticated,
};

export default AuthService;
