import React, { useState, useEffect } from "react";
import Navbar from "../common/navbar";
import Sidebar from "../sidebar/sidebar";
import { Button,Form,Container, Row, Col, Card, Modal,} from "react-bootstrap";
import { PlusCircle, DashCircle } from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast, ToastContainer } from "react-toastify";
import he from "he";
import { useSelector } from "react-redux";
import TourView from "./TourView";
import TourEdit from "./TourEdit";
import LocationForm from "./Location";
import PlacesAutocomplete, { geocodeByAddress, getLatLng,} from "react-places-autocomplete";
import { useNavigate } from "react-router-dom";
import TourDetails from "./TourDetails";
import cc from "currency-codes";

function TourCreate() {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const BaseUrl = process.env.REACT_APP_EUREKA_SERVER_ENDPOINT;
  const { userData, token } = useSelector((state) => state);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [error, setError] = useState(null);
  const [themes, setThemes] = useState([]);
  const [showButton, setShowButton] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [isloading, setIsloading] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [locationFormTourId, setLocationFormTourId] = useState(null);
  const [showDeletePopup, setshowDeletePopup] = useState(false);
  const [showViewPopup, setShowViewPopup] = useState(false);
  const [selectedTourId, setSelectedTourId] = useState({});
  const [cancellationPolicyId, setcancellationPolicyId] = useState({});
  const [selectedTourForView, setSelectedTourForView] = useState(null);
  const [selectedTourForEdit, setSelectedTourForEdit] = useState(null);
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
  const [address, setAddress] = useState("");
  const [placeId, setPlaceId] = useState("");
  const stateData = useSelector((state) => state);
  const [selections, setSelections] = useState([]);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showEditPopup1, setShowEditPopup1] = useState(false);
  const [personlizedSubCategory, setPersonlizedSubCategory] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [tagIdentifiers, setTagIdentifiers] = useState([]);
  const [selectedTagIdentifiers, setSelectedTagIdentifiers] = useState([]);
  const [selectedPersQueAns, setSelectedPersQueAns] = useState([]);
  const [cancellationPolicy, setCancellationPolicy] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [personalizeCat, setPersonalizeCat] = useState();
  const [personalizeCatQue, setPersonalizeCatQue] = useState();
  const [personalizeCatQueAns, setPersonalizeCatQueAns] = useState();
  const [valueFromNavbar, setValueFromNavbar] = useState(false);

  const navigate = useNavigate();

  const [tourData, setTourData] = useState({
    vendorId: "",
    title: "",
    description: "",
    maxParticipants: 0,
    price: 0,
    currency: "",
    address: "",
    latitude: 0,
    longitude: 0,
    highlights: "",
    included: "",
    notIncluded: "",
    additionalInformation: "",
    shortDescription: "",
    cancelation: "",
    personlizedSubCategory: [],
    capacity: "",
    tagIdentifier: "",
    question: [],
    themes: [],
    snapshotsImages: [],
    featureTour: 0,
    specialTour: 0,
    splTourToBMM: 0,
  });

  const initialTourData = {
    vendorId: "",
    title: "",
    description: "",
    maxParticipants: 0,
    price: 0,
    currency: "",
    address: "",
    latitude: 0,
    longitude: 0,
    highlights: "",
    included: "",
    notIncluded: "",
    additionalInformation: "",
    shortDescription: "",
    cancelation: "",
    personlizedSubCategory: [],
    capacity: "",
    tagIdentifier: "",
    question: [],
    themes: [],
    snapshotsImages: [],
    featureTour: 0,
    specialTour: 0,
    splTourToBMM: 0,
  };

  const resetForm = () => {
    setTourData(initialTourData);
    setAddress("");
    setSelections([]);
    setSelectedTagIdentifiers([]);
    setSelectedPersQueAns("");
    setPersonalizeCatQueAns("");
    setPersonalizeCatQue("");
    setPersonalizeCat("");
    setErrors({});

  };

  useEffect(() => {
    console.log("stateData", stateData);
    const currencies = cc.codes().map((code) => {
      const currency = cc.code(code);
      return {
        name: currency.name,
        code: currency.code,
      };
    });
    setCurrencyList(currencies);
  }, []);

  const getCurrencyOptions = () => {
    return cc.codes().map((code) => ({
      label: ` ${code}`,
      value: code,
    }));
  };

  useEffect(() => {
    setCurrencyOptions(getCurrencyOptions());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchTheme();
    getPersonlizedSubCategory();
   // getAllCountry();
    getCancellationPolicy();

    if (window.google && window.google.maps) {
      setIsGoogleMapsLoaded(true);
    }
  }, []);

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    console.log("Place Id",results[0].place_id)
    const latLng = await getLatLng(results[0]);
    console.log("latLng",latLng)
    setAddress(value);

    setTourData({
      ...tourData,
      latitude: latLng.lat,
      longitude: latLng.lng,
      placeId: results[0].place_id,
      address: value,
    });
  };

  const handleCloseLocationModal = () => {
    setShowLocationModal(false);
  };

  const handleRemoveSelection = (index) => {
    setSelections((prev) => prev.filter((_, idx) => idx !== index));
  };


  const handleAddThemeCategorySubcategory = () => {
    const selectedTheme = themes?.find(
      (theme) => theme?.themeId === tourData?.themeId
    );
    const selectedCategory = categories?.find(
      (category) => category?.categoryId === tourData?.categoryId
    );
    const selectedSubCategory = subCategories?.find(
      (sub) => sub?.subCategoryId === tourData?.subCategoryId
    );
  
    console.log("Selected Theme:", selectedTheme);
    console.log("Selected Category:", selectedCategory);
    console.log("Selected SubCategory:", selectedSubCategory);
  
    const newErrors = {};
  
    if (selectedTheme?.themeName === "Adventure") {
      if (!selectedCategory) {
        newErrors.categoryId = "Category is required.";
      }
      if (selectedCategory && !selectedSubCategory) {
        newErrors.subCategoryId = "Subcategory is required.";
      }
    }
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      console.error("Validation Errors:", newErrors);
      return;
    }
  
    const newSelection = {
      themeId: selectedTheme?.themeId,
      themeName: selectedTheme?.themeName,
      categoryId: selectedCategory?.categoryId || null,
      categoryName: selectedCategory?.categoryName || null,
      subCategoryId: selectedSubCategory?.subCategoryId || null,
      subCategoryName: selectedSubCategory?.subCategoryName || null,
    };
  
    const isDuplicate = selections.some(
      (selection) =>
        selection.themeId === newSelection.themeId &&
        selection.categoryId === newSelection.categoryId &&
        selection.subCategoryId === newSelection.subCategoryId
    );
  
    if (isDuplicate) {
      toast.warn("This combination is already added.");
      return;
    }
  
    const updatedSelections = [...selections, newSelection];
    setSelections(updatedSelections);
  
    setTourData((prevData) => ({
      ...prevData,
      themes: updatedSelections.map((selection) => ({
        themeId: selection.themeId,
        categoryId: selection.categoryId || null,
        subCategoryId: selection.subCategoryId || null,
      })),
    }));
  
    console.log("Updated Selections:", updatedSelections);
  
    setTourData((prevData) => ({
      ...prevData,
      themeId: "",
      categoryId: "",
      subCategoryId: "",
    }));
    setErrors({});
  };
  
  const fetchTheme = async () => {
    try {
      const response = await fetch(` ${BaseAPI}/tour/all/themes`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setThemes(data.data);
      setIsloading(false);
    } catch (error) {
      setError(error.toString());
      setIsloading(false);
      console.error("Error fetching themes:", error);
    }
  };

  const fetchCategoriesByTheme = async (themeId) => {
    try {
      const response = await fetch(`${BaseAPI}/tour/category/theme/${themeId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCategories(data.data);
    } catch (error) {
      console.error("Error fetching categories by theme:", error);
    }
  };

  const fetchSubCategories = async (categoryId) => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/subcategory/category/${categoryId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setSubCategories(data.data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    setTourData((prevData) => {
      
      const updatedData = {
        ...prevData,
        [name]: ["featureTour", "specialTour", "splTourToBMM"].includes(name)
          ? parseInt(value)
          : value,
      };
  
      console.log("updatedData.maxParticipants=====>",updatedData.maxParticipants)
      console.log("updatedData.capacity=====>",updatedData.capacity)


      if (name === "maxParticipants" && updatedData.maxParticipants <= updatedData.capacity) {
        setErrors((prevErrors) => {
          const { maxParticipants, ...restErrors } = prevErrors;
          return restErrors;  
        });
      }
  
      if (name === "capacity" && updatedData.capacity >= updatedData.maxParticipants) {
        setErrors((prevErrors) => {
          const { capacity, ...restErrors } = prevErrors;
          return restErrors;  
        });
      }
  

      return updatedData;
    });
  
    if (name === "themeId" && value) {
      setErrors((prevErrors) => {
        const { theme, ...restErrors } = prevErrors;
        return restErrors;
      });
    }
  
    if (name === "themeId") {
      if (value) {
        fetchCategoriesByTheme(value);
      } else {
        setCategories([]);
        setSubCategories([]);
      }
    } else if (name === "categoryId") {
      if (value) {
        fetchSubCategories(value);
      } else {
        setSubCategories([]);
      }
    }
  
    if (name === "personalizeCat") {
      let personalCatObject = personlizedSubCategory.find(
        (item) => item.personalizedCategoryId === value
      );
      getQuestion(personalCatObject.name);
      setPersonalizeCat(value);
    }
    if (name === "personalizeCatQue") {
      console.log("question", name);
      gettagIdentifiersByQuestionId(value);
      setPersonalizeCatQue(value);
    }
    if (name == "personalizeCatQueAns") {
      setPersonalizeCatQueAns(value);
    }
  };

  const handleInputTextChange = (value, name) => {
    const encodedHtml = he.encode(value, {
      useNamedReferences: true,
    });
    setTourData((prevData) => ({
      ...prevData,
      [name]: encodedHtml,
    }));
  };

  const handleCancellationChange = (e) => {
    setcancellationPolicyId(e.target.value);
  };

  
  useEffect(() => {
    const selectedTheme = themes?.find(
      (theme) => theme?.themeId == tourData?.themeId
    );
    const selectedCategory =
      categories?.find(
        (category) => category.categoryId == tourData.categoryId
      ) ?? null;
    const selectedSubCategory = subCategories?.find(
      (subCategory) => subCategory.subCategoryId == tourData.subCategoryId
    );

    if (selectedTheme && selectedCategory && selectedSubCategory) {
      const newThemes = [
        {
          themeId: selectedTheme?.themeId,
          themeId: selectedTheme?.themeId,
          themeName: selectedTheme?.themeName,
          categoryId: selectedCategory.categoryId,
          categoryName: selectedCategory.categoryName,
          subCategoryId: selectedSubCategory.subCategoryId,
          subCategoryName: selectedSubCategory.subCategoryName,
        },
      ];
      setTourData((prevData) => ({
        ...prevData,
        themes: [newThemes],
      }));
    }
  }, [
    themes,
    categories,
    subCategories,
    tourData?.themeId,
    tourData.categoryId,
    tourData.subCategoryId,
  ]);

  const validate = () => {
    const newErrors = {};
  
    if (!tourData.title.trim()) {
      newErrors.title = "Title is required";
    }
    if (!tourData.shortDescription.trim()) {
      newErrors.shortDescription = "Short Description is required";
    }
    if (!tourData.description.trim()) {
      newErrors.description = "Description is required";
    }
    if (!tourData.highlights.trim()) {
      newErrors.highlights = "Highlights are required";
    }
    if (!tourData.address.trim()) {
      newErrors.address = "Search Location is required";
    }
    if (!tourData.maxParticipants) {
      newErrors.maxParticipants = "Max Participants is required";
    }
    if (!tourData.capacity) {
      newErrors.capacity = "Max Capacity is required";
    }
    if (tourData.maxParticipants > tourData.capacity) {
      newErrors.maxParticipants = "Max Participants cannot be greater than Capacity";
    }
    if (tourData.capacity < tourData.maxParticipants) {
      newErrors.capacity = "Capacity cannot be less than Max Participants";
    }
    if (!tourData.cancelation.trim()) {
      newErrors.cancelation = "Cancellation is required";
    }
    if (!cancellationPolicyId) {
      newErrors.cancellationPolicyId = "Cancellation Policy is required";
    }
  
    if (selections.length === 0) {
      newErrors.theme = "At least one theme is required";
    }
  
    return newErrors;
  };

  const handleCreateTour = (e) => {
    e.preventDefault();
    console.log(selectedPersQueAns);
    const formErrors = validate();
    console.log(formErrors);
    
    if (Object.keys(formErrors).length > 0) {
      toast.warn(formErrors);

      setErrors(formErrors);
      setIsloading(false);

      return;
    }

    setErrors({});

    setIsloading(true);

    if (
      (tourData.themeId || tourData.categoryId || tourData.subCategoryId) &&
      selections.length === 0
    ) {
      alert("Please add the selection before creating the tour.");
      return;
    }
    const updatedTourData = {
      ...tourData,
      themes: selections.map((selection) => ({
        themeId: selection?.themeId,
        categoryId: selection.categoryId,
        subCategoryId: selection.subCategoryId,
      })),
      personalizedCategory: selectedPersQueAns,
    };

    const tourDataJson = JSON.stringify({
      title: updatedTourData.title,
      description: updatedTourData.description,
      maxParticipants: parseInt(updatedTourData.maxParticipants),
      address: updatedTourData.address,
      latitude: updatedTourData.latitude,
      longitude: updatedTourData.longitude,
      highlights: updatedTourData.highlights,
      capacity: updatedTourData.capacity,
      additionalInformation: updatedTourData.additionalInformation,
      cancellationPolicyId: cancellationPolicyId,
      cancelation: updatedTourData.cancelation,
      shortDescription: updatedTourData.shortDescription,
      included: updatedTourData.included,
      notIncluded: updatedTourData.notIncluded,
      themes: updatedTourData.themes,
      personalizedCategory: updatedTourData.personalizedCategory,

      featureTour: updatedTourData.featureTour,
      specialTour: updatedTourData.specialTour,
      splTourToBMM: updatedTourData.splTourToBMM,
      placeId: updatedTourData.placeId,
    });
    console.log("payload", tourDataJson);

    const apiUrl = `${BaseAPI}/tour/vendor/${userData.userId}/create`;

    fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: tourDataJson,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode == 200) {
          setShowForm(false);
          resetForm();

          toast.success("Tour created successfully!");
        } else {
          toast.error(data.message || "Failed to create tour.");
        }
      })
      .catch((error) => {
        toast.error("An error occurred while creating the tour.");
        console.error("Error:", error);
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const getCancellationPolicy = async () => {
    try {
      const response = await fetch(`${BaseAPI}/tour/all/cancellationPolicy`);
      if (!response.ok) {
        throw new Error(`Error fetching all tours: ${response.statusText}`);
      }
      const data = await response.json();
      setCancellationPolicy(data.data);
    } catch (error) {
      console.error("Error fetching all tours:", error);
    }
  };

  const getPersonlizedSubCategory = async () => {
    try {
      const response = await fetch(`${BaseAPI}/tour/all/personalized-category`);
      if (!response.ok) {
        throw new Error(`Error fetching all tours: ${response.statusText}`);
      }
      const data = await response.json();
      setPersonlizedSubCategory(data.data);
      console.log("personalizedsubcate", data.data);
    } catch (error) {
      console.error("Error fetching all tours:", error);
    }
  };

  const getQuestion = async (name) => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/questions/personalized-category/${name}`
      );
      if (!response.ok) {
        throw new Error(`Error fetching all tours: ${response.statusText}`);
      }
      const data = await response.json();
      setQuestionsData(data.data.question);
      console.log("questionId=======>", data.data.question);
    } catch (error) {
      console.error("Error fetching all tours:", error);
    }
  };

  const gettagIdentifiersByQuestionId = async (questionId) => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/tagIdentifiers/personalizedCategory/${questionId}`
      );
      if (!response.ok) {
        throw new Error("Error fetching all tours: ${response.statusText}");
      }
      const data = await response.json();
      setTagIdentifiers(data.data);
      console.log("AnswerID===========>", data.data);
    } catch (error) {
      console.error("Error fetching all tours:", error);
    }
  };

  const handleBackButton = () => {
    setShowForm(false);
    setShowButton(true);
    setShowTable(true);
    resetForm();
  };

  const handlePopupClose = () => {
    setshowDeletePopup(false);
  };


  const handleValueChange = (value) => {
    setValueFromNavbar(value);
  };

  const handleAddressChange = (value) => {
    setAddress(value);

    if (value === "") {
      setTourData((prevData) => ({
        ...prevData,
        latitude: "",
        longitude: "",
      }));
    }
  };

  const handleEditTour = (tour) => {
    setSelectedTourForEdit(tour);
    setcancellationPolicyId(tour.cancellationPolicyId);
    setShowEditPopup(true);
  };

  const handlePerCatQueAns = () => {
    const personalCatObject = personlizedSubCategory.find(
      (item) => item.personalizedCategoryId === personalizeCat
    );
  
    const personalizeCatQueObject = questionsData.find(
      (item) => item.questionId === personalizeCatQue
    );
  
    const personalizeCatQueAnsObject = tagIdentifiers.find(
      (item) => item.objectiveId === personalizeCatQueAns
    );
  
    if (!personalCatObject || !personalizeCatQueObject || !personalizeCatQueAnsObject) {
      toast.error("Please select valid options before adding.");
      return;
    }
  
    const tmpSelectedPersQueAns = {
      personalizeCat: personalCatObject,
      personalizeCatQue: {
        id: personalizeCatQueObject.questionId,
        name: personalizeCatQueObject.question,
      },
      personalizeCatQueAns: personalizeCatQueAnsObject,
    };
  
    const isDuplicate = (selectedPersQueAns || []).some(
      (entry) =>
        entry.personalizeCat.personalizedCategoryId ===
          tmpSelectedPersQueAns.personalizeCat.personalizedCategoryId &&
        entry.personalizeCatQue.id === tmpSelectedPersQueAns.personalizeCatQue.id &&
        entry.personalizeCatQueAns.objectiveId ===
          tmpSelectedPersQueAns.personalizeCatQueAns.objectiveId
    );
  
    if (isDuplicate) {
      toast.warn("This combination is already added.");
      return;
    }
  
    // Add new entry
    setSelectedPersQueAns([...selectedPersQueAns, tmpSelectedPersQueAns]);
    toast.success("Successfully added.");
  };
  
  const handleRemoveTagIdentifier = (index) => {
    const updatedTagIdentifiers = selectedPersQueAns.filter(
      (_, i) => i !== index
    );
    setSelectedPersQueAns(updatedTagIdentifiers);
  };

  return (
    <>
      <div>
        <div>
          <Sidebar />
        </div>
        <div>
          <Navbar onValueChange={handleValueChange} />
          <div
            className={`${
              !valueFromNavbar ? "layout-navbar-shifted" : "layout-navbar-full"
            }`}
          >
            <div className="layout-specing">
              <div className="d-md-flex justify-content-end align-items-end mb-2">
                <nav aria-label="breadcrumb" className="d-inline-block">
                  <div className="float-end me-4 mt-4">
                    {!showForm && !showEditPopup1 && (
                      <Button
                        variant="primary"
                        onClick={() => setShowForm(true)}
                      >
                        Create Tour
                      </Button>
                    )}
                  </div>
                </nav>
              </div>

              {!showForm ? (
                <TourDetails
                  handleEditTour={handleEditTour}
                  setShowEditPopup1={setShowEditPopup1}
                />
              ) : (
                <Container className="mt-3 ms-1">
                  <Row className="justify-content-center">
                    <Col lg={11} md={10}>
                      <div className="mb-2">
                        <span className="text-center mb-4 font-common fs-4 ">
                          Create Tour
                        </span>
                      </div>
                      <Card>
                        <Card.Body>
                          <Form>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Title<span style={{ color: "red" }}> *</span>
                              </Form.Label>{" "}
                              <Form.Control
                                type="text"
                                placeholder="Enter tour name"
                                name="title"
                                value={tourData.title}
                                onChange={handleInputChange}
                                isInvalid={!!errors.title}
                              />
                              {errors.title && (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {errors.title}
                                </div>
                              )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>
                                Short Description
                                <span style={{ color: "red" }}> *</span>
                              </Form.Label>
                              <ReactQuill
                                as="textarea"
                                rows={3}
                                className="h-50"
                                name="shortDescription"
                                required
                                value={he.decode(tourData.shortDescription)}
                                onChange={(value) =>
                                  handleInputTextChange(
                                    value,
                                    "shortDescription"
                                  )
                                }
                                isInvalid={!!errors.shortDescription}
                              />
                              {errors.shortDescription && (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {errors.shortDescription}
                                </div>
                              )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>
                                Description
                                <span style={{ color: "red" }}> *</span>
                              </Form.Label>
                              <ReactQuill
                                as="textarea"
                                rows={3}
                                name="description"
                                value={he.decode(tourData.description)}
                                onChange={(value) =>
                                  handleInputTextChange(value, "description")
                                }
                                isInvalid={!!errors.description}
                              />
                              {errors.description && (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {errors.description}
                                </div>
                              )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>
                                Highlights
                                <span style={{ color: "red" }}> *</span>
                              </Form.Label>
                              <ReactQuill
                                name="highlights"
                                theme="snow"
                                value={he.decode(tourData.highlights)}
                                onChange={(value) =>
                                  handleInputTextChange(value, "highlights")
                                }
                                isInvalid={!!errors.highlights}
                              />
                              {errors.highlights && (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {errors.highlights}
                                </div>
                              )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>What's Included</Form.Label>
                              <ReactQuill
                                as="textarea"
                                type="textarea"
                                name="included"
                                value={he.decode(tourData.included)}
                                onChange={(value) =>
                                  handleInputTextChange(value, "included")
                                }
                              />
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>What's Not Included</Form.Label>
                              <ReactQuill
                                type="textarea"
                                as="textarea"
                                name="notIncluded"
                                value={he.decode(tourData.notIncluded)}
                                onChange={(value) =>
                                  handleInputTextChange(value, "notIncluded")
                                }
                              />
                            </Form.Group>

                            <Form.Group className="mt-2 position-relative">
                              <Form.Label>
                                Search Location{" "}
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              {isGoogleMapsLoaded ? (
                                <PlacesAutocomplete
                                  value={address}
                                  onChange={handleAddressChange}
                                  onSelect={handleSelect}
                                >
                                  {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                  }) => (
                                    <div
                                      className="autocomplete-container"
                                      style={{ position: "relative" }}
                                    >
                                      <input
                                        {...getInputProps({
                                          placeholder: "Search Places ...",
                                          className: "form-control",
                                        })}
                                      />
                                      <div
                                        className="autocomplete-dropdown"
                                        style={{
                                          position: "absolute",
                                          zIndex: 1000,
                                          backgroundColor: "#fff",
                                          border: "1px solid #ddd",
                                          borderRadius: "4px",
                                          width: "100%",
                                        }}
                                      >
                                        {loading && (
                                          <div className="autocomplete-item">
                                            Loading...
                                          </div>
                                        )}
                                        {suggestions.map((suggestion) => {
                                          const isActive = suggestion.active;
                                          const style = {
                                            backgroundColor: isActive
                                              ? "#f1f1f1"
                                              : "#ffffff",
                                            padding: "10px",
                                            borderBottom: "1px solid #eee",
                                            cursor: "pointer",
                                          };
                                          return (
                                            <div
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                { style }
                                              )}
                                              key={suggestion.placeId}
                                              className="autocomplete-item"
                                            >
                                              {suggestion.description}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </PlacesAutocomplete>
                              ) : (
                                <div>Loading Google Maps...</div>
                              )}
                              {errors.address && (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {errors.address}
                                </div>
                              )}
                            </Form.Group>

                            <Row className="mb-3 mt-4">
                              <Col md={6}>
                                <Form.Group>
                                  <Form.Label>Latitude</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Latitude"
                                    name="latitude"
                                    value={tourData.latitude}
                                    onChange={handleInputChange}
                                  />
                                </Form.Group>
                              </Col>

                              <Col md={6}>
                                <Form.Group>
                                  <Form.Label>Longitude</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Longitude"
                                    name="longitude"
                                    value={tourData.longitude}
                                    onChange={handleInputChange}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>

                            <Form.Group className="mb-3">
                              <Form.Label>Additional Information</Form.Label>
                              <ReactQuill
                                as="textarea"
                                rows={2}
                                name="additionalInformation"
                                value={he.decode(
                                  tourData.additionalInformation
                                )}
                                onChange={(value) =>
                                  handleInputTextChange(
                                    value,
                                    "additionalInformation"
                                  )
                                }
                              />
                            </Form.Group>

                            <Row className="mb-3">
                              <Col md={6}>
                                <Form.Group>
                                  <Form.Label>
                                    Max Participants{" "}
                                    <span style={{ color: "red" }}> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter tour maxParticipants"
                                    name="maxParticipants"
                                    value={tourData.maxParticipants}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      if (value >= 0 || value === "") {
                                        handleInputChange(e);
                                      }
                                    }}
                                    isInvalid={!!errors.maxParticipants}
                                  />
                                  {errors.maxParticipants && (
                                    <div
                                      style={{ color: "red", fontSize: "14px" }}
                                    >
                                      {errors.maxParticipants}
                                    </div>
                                  )}
                                </Form.Group>
                              </Col>

                              <Col>
                                <Form.Group>
                                  <Form.Label>
                                    Tour Max Capacity{" "}
                                    <span style={{ color: "red" }}> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="capacity"
                                    placeholder="Enter tour max capacity"
                                    value={tourData.capacity}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      if (value >= 0 || value === "") {
                                        handleInputChange(e);
                                      }
                                    }}
                                    isInvalid={!!errors.capacity}
                                  />
                                  {errors.capacity && (
                                    <div
                                      style={{ color: "red", fontSize: "14px" }}
                                    >
                                      {errors.capacity}
                                    </div>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>

                            <Row className="mb-3">
                              <Col md={6}>
                                <Form.Group>
                                  <Form.Label>
                                    Cancellation{" "}
                                    <span style={{ color: "red" }}> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="cancelation"
                                    placeholder="Enter tour cancellation Days"
                                    value={tourData.cancelation}
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.cancelation}
                                  />
                                  {errors.cancelation && (
                                    <div
                                      style={{ color: "red", fontSize: "14px" }}
                                    >
                                      {errors.cancelation}
                                    </div>
                                  )}
                                </Form.Group>
                              </Col>

                              <Col md={6}>
                                <Form.Group>
                                  <Form.Label>
                                    Cancellation Policy{" "}
                                    <span style={{ color: "red" }}> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    as="select"
                                    onChange={(e) => {
                                      handleCancellationChange(e);
                                      setErrors((prevErrors) => ({
                                        ...prevErrors,
                                        cancellationPolicyId: "",
                                      }));
                                    }}
                                    name="cancellationPolicyId"
                                    value={cancellationPolicyId || ""}
                                    isInvalid={!!errors.cancellationPolicyId}
                                  >
                                    <option value="">
                                      Select cancellation policy
                                    </option>
                                    {cancellationPolicy &&
                                      cancellationPolicy.map((policy) => (
                                        <option
                                          key={policy.cancellationPolicyId}
                                          value={policy.cancellationPolicyId}
                                        >
                                          {policy.policyName}
                                        </option>
                                      ))}
                                  </Form.Control>
                                  {errors.cancellationPolicyId && (
                                    <Form.Text
                                      style={{ color: "red", fontSize: "14px" }}
                                    >
                                      {errors.cancellationPolicyId}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={4}>
                                <Form.Group>
                                  <Form.Label>Feature Tour</Form.Label>
                                  <div>
                                    <Form.Check
                                      inline
                                      type="radio"
                                      label="Yes"
                                      name="featureTour"
                                      value={1}
                                      checked={tourData.featureTour === 1}
                                      onChange={handleInputChange}
                                    />
                                    <Form.Check
                                      inline
                                      type="radio"
                                      label="No"
                                      name="featureTour"
                                      value={0}
                                      checked={tourData.featureTour === 0}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col md={4}>
                                <Form.Group>
                                  <Form.Label>Special Tour</Form.Label>
                                  <div>
                                    <Form.Check
                                      inline
                                      type="radio"
                                      label="Yes"
                                      name="specialTour"
                                      value={1}
                                      checked={tourData.specialTour === 1}
                                      onChange={handleInputChange}
                                    />
                                    <Form.Check
                                      inline
                                      type="radio"
                                      label="No"
                                      name="specialTour"
                                      value={0}
                                      checked={tourData.specialTour === 0}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col md={4}>
                                <Form.Group>
                                  <Form.Label>Special BMM Tour</Form.Label>
                                  <div>
                                    <Form.Check
                                      inline
                                      type="radio"
                                      label="Yes"
                                      name="splTourToBMM"
                                      value={1}
                                      checked={tourData.splTourToBMM === 1}
                                      onChange={handleInputChange}
                                    />
                                    <Form.Check
                                      inline
                                      type="radio"
                                      label="No"
                                      name="splTourToBMM"
                                      value={0}
                                      checked={tourData.splTourToBMM === 0}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>

                            <Row>
                              <div className="row">
                                <Col md={4}>
                                  <div className="select-wrapper">
                                    <Form.Label className="form-label">
                                      Select Theme{" "}
                                      <span style={{ color: "red" }}> *</span>
                                    </Form.Label>
                                    <Form.Control
                                      as="select"
                                      name="themeId"
                                      value={tourData?.themeId}
                                      onChange={handleInputChange}
                                    >
                                      <option value="">Select a Theme</option>
                                      {themes &&
                                        Array.isArray(themes) &&
                                        themes.map((theme) => (
                                          <option
                                            key={theme?.themeId || theme?.id}
                                            value={theme?.themeId || theme?.id}
                                          >
                                            {theme?.themeName || theme?.name}
                                          </option>
                                        ))}
                                    </Form.Control>
                                    {errors.theme && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {errors.theme}
                                      </div>
                                    )}
                                  </div>
                                </Col>

                                <Col md={4}>
                                  <div className="select-wrapper">
                                    <Form.Label className="form-label">
                                      Category{" "}
                                      <span style={{ color: "red" }}> *</span>
                                    </Form.Label>
                                    <Form.Control
                                      as="select"
                                      name="categoryId"
                                      value={tourData.categoryId}
                                      onChange={handleInputChange}
                                    >
                                      <option value="">
                                        Select a Category
                                      </option>
                                      {categories &&
                                        Array.isArray(categories) &&
                                        categories.map((category) => (
                                          <option
                                            key={category.categoryId}
                                            value={category.categoryId}
                                          >
                                            {category.categoryName}
                                          </option>
                                        ))}
                                    </Form.Control>
                                    {errors.categoryId && (
                                      <div style={{ color: "red" }}>
                                        {errors.categoryId}
                                      </div>
                                    )}
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <div className="select-wrapper">
                                    <Form.Label className="form-label">
                                      Subcategory{" "}
                                      <span style={{ color: "red" }}> *</span>
                                    </Form.Label>
                                    <div className="d-flex align-items-center">
                                      <Form.Control
                                        as="select"
                                        name="subCategoryId"
                                        value={tourData.subCategoryId}
                                        onChange={handleInputChange}
                                      >
                                        <option value="">
                                          Select a Subcategory
                                        </option>
                                        {subCategories &&
                                          Array.isArray(subCategories) &&
                                          subCategories.map((subCategory) => (
                                            <option
                                              key={subCategory.subCategoryId}
                                              value={subCategory.subCategoryId}
                                            >
                                              {subCategory.subCategoryName}
                                            </option>
                                          ))}
                                      </Form.Control>

                                      <Button
                                        onClick={
                                          handleAddThemeCategorySubcategory
                                        }
                                        className=" ms-2 theme-btn"
                                      >
                                        <PlusCircle
                                          style={{ fontSize: "15px" }}
                                        />

                                        <span className=" ms-2">Add</span>
                                      </Button>
                                    </div>
                                    {errors.subCategoryId && (
                                      <div style={{ color: "red" }}>
                                        {errors.subCategoryId}
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              </div>
                            </Row>

                            <Row>
                              {selections.length > 0 &&
                                selections.map((selection, index) => (
                                  <li
                                    key={index}
                                    className="d-flex align-items-center mb-1 p-1 rounded ms-3 me-3"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="d-flex justify-content-between w-100">
                                      <div className=" font-common">
                                        {selection.themeName}
                                      </div>
                                      <div className="font-common">
                                        {selection.categoryName}
                                      </div>
                                      <div className="text-primary font-common me-3">
                                        {selection.subCategoryName}
                                      </div>
                                    </div>
                                    <DashCircle
                                      style={{
                                        cursor: "pointer",
                                        color: "#dc3545",
                                        fontSize: "15px",
                                      }}
                                      onClick={() =>
                                        handleRemoveSelection(index)
                                      }
                                      className="me-5"
                                    />
                                  </li>
                                ))}
                            </Row>

                            <Row>
                              <div className="row mt-3">
                                <Col md={4}>
                                  <div className="select-wrapper">
                                    <Form.Label className="form-label">
                                      Select Personalized Subcategories{" "}
                                      {/* <span style={{ color: "red" }}>*</span> */}
                                    </Form.Label>
                                    <Form.Select
                                      name="personalizeCat"
                                      value={personalizeCat}
                                      onChange={handleInputChange}
                                    >
                                      <option value="">
                                        Select Personalized Categroy
                                      </option>
                                      {Array.isArray(personlizedSubCategory) &&
                                        personlizedSubCategory.map((subCat) => (
                                          <option
                                            key={subCat.personalizedCategoryId}
                                            value={
                                              subCat.personalizedCategoryId
                                            }
                                          >
                                            {subCat.name}
                                          </option>
                                        ))}
                                    </Form.Select>
                                  </div>
                                </Col>

                                <Col md={4}>
                                  <div className="select-wrapper">
                                    <Form.Label className="form-label">
                                      Select Question
                                    </Form.Label>
                                    <Form.Select
                                      name="personalizeCatQue"
                                      value={personalizeCatQue}
                                      onChange={handleInputChange}
                                    >
                                      <option value="">Select Question</option>
                                      {Array.isArray(questionsData) &&
                                        questionsData.map((question) => (
                                          <option
                                            key={question.questionId}
                                            value={question.questionId}
                                          >
                                            {question.question}
                                          </option>
                                        ))}
                                    </Form.Select>
                                  </div>
                                </Col>

                                <Col md={4}>
                                  <div className="select-wrapper">
                                    <Form.Label className="form-label">
                                      Select Tag Identifier
                                    </Form.Label>
                                    <div className="d-flex align-items-center">
                                      <Form.Select
                                        name="personalizeCatQueAns"
                                        value={personalizeCatQueAns}
                                        onChange={handleInputChange}
                                        className="flex-grow-1"
                                      >
                                        <option value="">
                                          Select Tag Identifier
                                        </option>
                                        {Array.isArray(tagIdentifiers) &&
                                          tagIdentifiers.map((tag) => (
                                            <option
                                              key={tag.objectiveId}
                                              value={tag.objectiveId}
                                            >
                                              {tag.tagIdentifier}
                                            </option>
                                          ))}
                                      </Form.Select>

                                      <Button
                                        onClick={handlePerCatQueAns}
                                        className=" ms-2 theme-btn"
                                      >
                                        <PlusCircle
                                          style={{ fontSize: "15px" }}
                                        />

                                        <span className=" ms-2">Add</span>
                                      </Button>
                                    </div>
                                  </div>
                                </Col>

                                <Row>
                                  {selectedPersQueAns.length > 0 && (
                                    <div className="mt-3">
                                      <ul className="list-unstyled">
                                        {selectedPersQueAns.map(
                                          (perObject, index) => (
                                            <li
                                              key={index}
                                              className="d-flex align-items-center mb-1 p-2 rounded "
                                              style={{ marginBottom: "15px" }}
                                            >
                                              <div className="d-flex justify-content-between w-100">
                                                <div className="fw-bold">
                                                  {
                                                    perObject.personalizeCat
                                                      .name
                                                  }
                                                </div>
                                                <div className="text-muted">
                                                  {
                                                    perObject.personalizeCatQue
                                                      .name
                                                  }
                                                </div>
                                                <div className="text-primary">
                                                  {
                                                    perObject
                                                      .personalizeCatQueAns
                                                      .tagIdentifier
                                                  }
                                                </div>
                                              </div>

                                              <DashCircle
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#dc3545",
                                                  fontSize: "15px",
                                                  marginLeft: "15px",
                                                }}
                                                onClick={() =>
                                                  handleRemoveTagIdentifier(
                                                    index
                                                  )
                                                }
                                              />
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  )}
                                </Row>
                              </div>
                            </Row>

                            <div className="d-flex justify-content-center mt-4">
                              <Button
                                type="submit"
                                variant="primary"
                                className="me-2"
                                disabled={isloading}
                                onClick={handleCreateTour}
                              >
                                {isloading ? "Creating..." : "Create"}
                              </Button>

                              <Button
                                variant="secondary"
                                onClick={handleBackButton}
                              >
                                Cancel
                              </Button>
                            </div>
                          </Form>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              )}
              <LocationForm
                show={showLocationModal}
                handleClose={handleCloseLocationModal}
                tourId={locationFormTourId}
              />
              <TourView
                show={showViewPopup}
                handleClose={() => setShowViewPopup(false)}
                selectedTourForView={selectedTourForView}
              />
              {showEditPopup && (
                <TourEdit
                  show={showEditPopup}
                  handleClose={() => setShowEditPopup(false)}
                  tour={selectedTourForEdit}
                  cancellationPolicyId={cancellationPolicyId}
                />
              )}
            </div>

            <Modal show={showDeletePopup} onHide={handlePopupClose}>
              <Modal.Header closeButton>
                <Modal.Title>Delete Schedule</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="delete center">
                  Are you want to delete tour?
                </div>
              </Modal.Body>
              <Button
                variant="btn btn-secondary w-25 ms-3 close"
                onClick={handlePopupClose}
              >
                Close
              </Button>
              &nbsp;
            </Modal>
          </div>
          <ToastContainer />
        </div>
      </div>
    </>
  );
}

export default TourCreate;
