import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Carousel,
  Button,
  Modal,
  Form,
  Table,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import {
  ArrowRight,
  ArrowLeft,
  DashCircle,
  ThreeDotsVertical,
  PencilFill,
  TrashFill,
  EyeFill,
} from "react-bootstrap-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../tour/tours.css";
import { useSelector } from "react-redux";
import blankImg from "../../assets/images/download (2).png";
import uploadImageIcon from "../../assets/images/downloadimageIcon.png";
import { useNavigate } from "react-router-dom";
import cc from "currency-codes";
import Select from "react-select";
import CurrencyFlag from "react-currency-flags";
import TourEdit from "./TourEdit";
import axios from "axios";

const TourDetails = ({ setShowEditPopup1 }) => {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const { userData, token } = useSelector((state) => state);
  const [displayedTours, setDisplayedTours] = useState([]);
  const [hasMoreTours, setHasMoreTours] = useState(false);
  const [allTours, setAllTours] = useState([]);
  const [uploadImage, setUploadImage] = useState(false);
  const [selectedTourId, setSelectedTourId] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [displayedFile, setDisplayedFile] = useState([]);
  const [selectedSnapshotsFile, SetselectedSnapshotsFile] = useState([]);
  const [displayedSnapshotFiles, setDisplayedSnapshotFiles] = useState([]);
  const fileInputRefFeatured = useRef(null);
  const fileInputRefSnapshots = useRef(null);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [snapshotImages, setSnapshotImages] = useState([]);
  const [themes, setThemes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [visibleToursCount, setVisibleToursCount] = useState(8);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const navigate = useNavigate();
  const [currencyList, setCurrencyList] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTour, setSelectedTour] = useState(null);
  const [tourToDelete, setTourToDelete] = useState(null);
  const [loadingFeatured, setLoadingFeatured] = useState(false);
  const [loadingSnapshot, setLoadingSnapshot] = useState(false);
  const [errors, setErrors] = useState({});
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [deleteImageConfirm, setDeleteImageConfirm] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);

  const [formData, setFormData] = useState({
    scheduleTitle: "",
    startDate: "",
    duration: "",
    durationTime: "",
    price: "",
    currency: "",
    discount: "",
  });

  useEffect(() => {
    const currencies = cc.codes().map((code) => {
      const currency = cc.code(code);
      return {
        name: currency.name,
        code: currency.code,
      };
    });
    setCurrencyList(currencies);
  }, []);

  useEffect(() => {
    const options = cc.codes().map((code) => ({
      value: code,
      label: `${code}`,
    }));
    setCurrencyOptions(options);
  }, []);

  const handleSelectChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      currency: selectedOption ? selectedOption.value : "",
    }));
    setErrors({
      ...errors,
      currency: "",
    });
  };

  useEffect(() => {
    fetchAllToursByVendorId();
    fetchAllThemes();
  }, []);

  const fetchAllToursByVendorId = async () => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/enterprise/${userData.data.enterpriseCompanyId}`
      );
      if (!response.ok) {
        throw new Error(`Error fetching all tours: ${response.statusText}`);
      }
      const data = await response.json();

      const activeTours = data.data.filter((tour) => tour.status === 1);
      const rearrangedData = activeTours.map((item) => ({
        ...item,
        tourAssets: [
          ...item.tourAssets.filter(
            (image) => image.assetCategory === "FEATURED"
          ),
          ...item.tourAssets.filter(
            (image) => image.assetCategory !== "FEATURED"
          ),
        ],
      }));

      setAllTours(rearrangedData);
      console.log("activeTours", rearrangedData);
      setDisplayedTours(rearrangedData.slice(0, visibleToursCount));
      setHasMoreTours(rearrangedData.length > visibleToursCount);
    } catch (error) {
      console.error("Error fetching all tours:", error);
    }
  };

  const handleShowMoreTours = () => {
    const nextToursCount = visibleToursCount + 8;
    setDisplayedTours(allTours.slice(0, nextToursCount));
    setVisibleToursCount(nextToursCount);
    setHasMoreTours(allTours.length > nextToursCount);
  };

  const handleShowLessTours = () => {
    setDisplayedTours(allTours.slice(0, 8));
    setVisibleToursCount(8);
    setHasMoreTours(allTours.length > 8);
  };

  const handleTourCardClick = (tour) => {
    const tourId = tour.tourId;
    // const url = `https://eurekatrip.com/#/tour/${tourId}`;
    const url = `https://uat-eurekatrip.azurewebsites.net/#/tour/${tourId}`;

    window.open(url, "_blank");
  };

  const handleTourScheduledCardClick = (tour) => {
    console.log("tour before navigating:", tour);
    console.log("tour  id:", tour.tourId);

    if (tour && tour.tourId) {
      navigate("/scheduled-tour", { state: { navigatedTourId: tour.tourId } });
    } else {
      console.error("tour object or tourId is missing");
    }
  };

  const uploadImages = (tourData) => {
    setSelectedTourId(tourData.tourId);
    setUploadImage(true);

    const featuredImage = tourData.tourAssets.find(
      (asset) => asset.assetCategory === "FEATURED"
    );

    if (featuredImage) {
      const fullImageUrl = `${featuredImage.hostUrl}${featuredImage.relativePath}`;
      setUploadedImageUrl(fullImageUrl);
    }
  };

  const handleFeatured = async () => {
    if (selectedFile.length > 0 && displayedFile.length === 0) {
      toast.warn("Please add the selected file before uploading.");
      return;
    }

    setLoadingFeatured(true);

    if (Array.isArray(displayedFile) && displayedFile.length > 0) {
      const formData = new FormData();
      formData.append("imageType", "featured");
      displayedFile.forEach((file) => {
        formData.append("file", file);
      });
      try {
        const response = await fetch(
          `${BaseAPI}/tour/${selectedTourId}/uploadFile`,
          {
            method: "POST",
            body: formData,
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        console.log("uploadfile", response);
        toast.success("File Uploaded successfully");
        fetchAllToursByVendorId();
      } catch (error) {
        console.error("Error submitting category:", error);
      } finally {
        setLoadingFeatured(false);
      }
    }
  };

  const handleSnapShot = async () => {
    if (
      selectedSnapshotsFile.length > 0 &&
      displayedSnapshotFiles.length === 0
    ) {
      toast.warn("Please add the selected files before uploading.");
      return;
    }

    if (
      Array.isArray(displayedSnapshotFiles) &&
      displayedSnapshotFiles.length > 0
    ) {
      setLoadingSnapshot(true);
      const formData = new FormData();
      formData.append("imageType", "snapshot");
      displayedSnapshotFiles.forEach((file) => {
        formData.append("files", file);
      });
      try {
        console.log(`Selected Tour ID====>: ${selectedTourId}`);

        const response = await fetch(
          `${BaseAPI}/tour/${selectedTourId}/uploadSnaphots`,
          {
            method: "POST",
            body: formData,
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        toast.success("File Uploaded  successfully");
        fetchAllToursByVendorId();
        setUploadImage(false);

        console.log("Category submitted successfully");
      } catch (error) {
        console.error("Error submitting category:", error);
      } finally {
        setLoadingSnapshot(false);
      }
    }
  };

  const fetchAllThemes = async () => {
    try {
      const response = await fetch(`${BaseAPI}/tour/all/themes`);
      if (!response.ok) {
        throw new Error("Failed to fetch themes");
      }
      const data = await response.json();
      setThemes(data.data);
    } catch (error) {
      console.error("Error fetching themes:", error);
    }
  };

  const handleRemoveFile = (index) => {
    const filteredFiles = displayedFile.filter((_, i) => i !== index);
    setDisplayedFile(filteredFiles);
    setSelectedFile(filteredFiles);
    if (filteredFiles.length === 0) {
      setIsInputDisabled(false);
      fileInputRefFeatured.current.value = "";
    }
  };

  const handleFeaturedImageChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setFeaturedImage(file);
      setDisplayedFile((prevFiles) => [...prevFiles, file]);
      setSelectedFile([file]);
    }
  };

  const handleSnapshotImagesChange = (event) => {
    if (event.target.files.length > 0) {
      const filesArray = Array.from(event.target.files);
      setSnapshotImages([...snapshotImages, ...filesArray]);
      setDisplayedSnapshotFiles((prevFiles) => [...prevFiles, ...filesArray]);
    }
  };

  const handleSnapshotRemoveFile = (index) => {
    const filteredFiles = displayedSnapshotFiles.filter((_, i) => i !== index);
    setDisplayedSnapshotFiles(filteredFiles);
    if (filteredFiles.length === 0) {
      fileInputRefSnapshots.current.value = "";
    }
  };

  const handleUploadImageClose = () => {
    setUploadImage(false);
    setDisplayedFile([]);
    setDisplayedSnapshotFiles([]);
    setSelectedFile([]);
    SetselectedSnapshotsFile([]);
    fileInputRefFeatured.current.value = "";
    fileInputRefSnapshots.current.value = "";
  };

  const handleShowModal = (tour) => {
    setFormData({
      scheduleTitle: "",
      startDate: "",
      duration: "",
      durationTime: "",
      price: "",
      currency: "",
      discount: "",
    });
    setErrors({});
    setShowModal(true);
    setSelectedTourId(tour.tourId);
  };

  const handleCloseModal = () => {
    setFormData({
      scheduleTitle: "",
      startDate: "",
      duration: "",
      durationTime: "",
      price: "",
      currency: "",
      discount: "",
    });
    setErrors({});
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (
      name === "startDate" &&
      new Date(value) < new Date().setHours(0, 0, 0, 0)
    ) {
      setErrors({
        ...errors,
        [name]: "The start date cannot be in the past.",
      });
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.scheduleTitle) {
      newErrors.scheduleTitle = "Schedule title is required.";
    }
    if (!formData.startDate) {
      newErrors.startDate = "Start date is required.";
    }
    if (!formData.duration || formData.duration <= 0) {
      newErrors.duration = "Duration must be greater than 0.";
    }
    if (!formData.durationTime) {
      newErrors.durationTime = "Duration time is required.";
    }
    if (
      !formData.discount ||
      formData.discount < 0 ||
      formData.discount > 100
    ) {
      newErrors.discount = "Discount must be between 0 and 100.";
    }
    if (!formData.price || isNaN(formData.price) || formData.price <= 0) {
      newErrors.price = "Price must be a valid number greater than 0.";
    }
    if (!formData.currency) {
      newErrors.currency = "Currency is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      const response = await fetch(
        `${BaseAPI}/tour/${selectedTourId}/schedule`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        toast.success("Schedule created successfully");
        navigate("/scheduled-tour");
        fetchAllToursByVendorId();
        setShowSubmitButton(true);
        setFormData({
          scheduleTitle: "",
          startDate: "",
          duration: "",
          durationTime: "",
          price: "",
          // currency:"",
          discount: "",
        });
        handleCloseModal();
      } else {
        const errorText = await response.text();
        console.error("Failed to create schedule:", errorText);
        toast.error("Failed to create schedule");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while creating the schedule");
    }
  };

  const handleCloseEditModalAndRefresh = () => {
    setShowEditModal(false);
    setSelectedTour(null);
    fetchAllToursByVendorId();
  };

  const handleThreeDotsClick = (tour) => {
    setSelectedTour(tour);
    setShowEditModal(true);
    setShowEditPopup1(true);
  };

  const confirmDeleteTour = (tour) => {
    setTourToDelete(tour);
    setShowDeleteConfirmModal(true);
  };

  const handleCloseDeleteConfirmModal = () => {
    setShowDeleteConfirmModal(false);
    setTourToDelete(null);
  };

  const handleDeleteTour = async () => {
    if (!tourToDelete) return;

    try {
      const response = await axios.post(
        `${BaseAPI}/tour/delete/tour/${tourToDelete.tourId}`
      );

      if (response.status === 200 && response.data.status === "Success") {
        toast.success("Tour Deleted Successfully");
        setAllTours((prevTours) =>
          prevTours.filter((tour) => tour.tourId !== tourToDelete.tourId)
        );
        setDisplayedTours((prevDisplayed) =>
          prevDisplayed.filter((tour) => tour.tourId !== tourToDelete.tourId)
        );
      } else {
        toast.error("Error deleting tour");
        console.error("Failed to delete tour:", response.data.statusMessage);
      }
    } catch (error) {
      console.error("Error deleting tour:", error);
      toast.error("Error deleting tour");
    } finally {
      setShowDeleteConfirmModal(false);
      setTourToDelete(null);
    }
  };

  const openDeleteImageConfirmModal = (assetId, tourId) => {
    setSelectedImage({ assetId, tourId });
    setDeleteImageConfirm(true);
  };

  const closeDeleteImageConfirmModal = () => {
    setDeleteImageConfirm(false);
    setSelectedImage(null);
  };

  const handleDeleteImage = async () => {
    const { assetId, tourId } = selectedImage;

    if (!assetId || !tourId) {
      toast.error("Invalid asset or tour ID");
      return;
    }

    try {
      const response = await axios.post(
        `${BaseAPI}/tour/delete/tour-assets/${assetId}`
      );

      console.log("API Response:", response.data);

      if (response.status === 200) {
        toast.success("Image deleted successfully");

        fetchAllToursByVendorId();
      } else {
        const errorMessage =
          response.data.statusMessage ||
          "Error occurred while deleting the image";

        toast.error(errorMessage);
        console.error("Failed to delete image:", errorMessage);
      }
    } catch (error) {
      const errorDetails = error.response?.data?.statusMessage || error.message;
      console.error("Error deleting image:", errorDetails);
      toast.error("An error occurred while deleting the image");
    }
    closeDeleteImageConfirmModal();
  };

  const formatOptionLabel = ({ value, label }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <CurrencyFlag currency={value} size="lg" style={{ marginRight: 10 }} />
      {label}
    </div>
  );

  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "4px",
      borderRadius: "2px",
      border: "1px solid #ced4da",
      boxShadow: "none",
      width: "180px",
    }),
    option: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      padding: "8px 12px",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "8px",
      overflow: "hidden",
      zIndex: 9999,
    }),
  };

  return (
    <Container fluid className="custom-container h-auto mt-4 px-5 font-common">
      {!showEditModal && (
        <>
          <h1 className="text-start feature-heading font-common">Tours</h1>
        </>
      )}{" "}
      {!showEditModal && (
        <>
          <Row>
            {displayedTours.map((tour, idx) => (
              <Col xs={12} sm={6} md={4} lg={3} key={idx}>
                <Card className="h-100" style={{ border: "none" }}>
                  <div className="carousel-hover">
                    <Carousel
                      className="carousel-overlay"
                      interval={null}
                      nextIcon={
                        <span
                          style={{
                            backgroundColor: "black",
                            borderRadius: "50%",
                            padding: "5px",
                          }}
                        >
                          <ArrowRight style={{ color: "white" }} />
                        </span>
                      }
                      prevIcon={
                        <span
                          style={{
                            backgroundColor: "black",
                            borderRadius: "50%",
                            padding: "5px",
                          }}
                        >
                          <ArrowLeft style={{ color: "white" }} />
                        </span>
                      }
                    >
                      {tour.tourAssets && tour.tourAssets.length > 0 ? (
                        tour.tourAssets.map((asset, assetIdx) => (
                          <Carousel.Item
                            key={assetIdx}
                            className="card-img-container"
                          >
                            <div>
                              <img
                                // onClick={() => handleTourCardClick(tour)}
                                onClick={() =>
                                  handleTourScheduledCardClick(tour)
                                }
                                src={asset.assetUrl || blankImg}
                                alt={`Slide ${assetIdx}`}
                                onError={(e) => {
                                  console.log(
                                    "Image failed to load. Setting to default image."
                                  );
                                  e.target.src = blankImg;
                                }}
                                className="img-fluid"
                                style={{ cursor: "pointer" }}
                              />

                              <TrashFill
                                className="delete-icon"
                                onClick={() =>
                                  openDeleteImageConfirmModal(
                                    asset.assetId,
                                    tour.tourId
                                  )
                                }
                              ></TrashFill>
                            </div>
                          </Carousel.Item>
                        ))
                      ) : (
                        <Carousel.Item className="card-img-container">
                          <img
                            // onClick={() => handleTourCardClick(tour)}
                            onClick={() =>
                              handleTourScheduledCardClick(tour.tourId)
                            }
                            src={blankImg}
                            alt="Default image"
                            className="img-fluid"
                            style={{ cursor: "pointer" }}
                          />
                        </Carousel.Item>
                      )}
                    </Carousel>
                    <div className="overlay-text">
                      {tour.tourTheme[0]?.theme.themeName}
                    </div>

                    <div className="three-dots-container">
                      <Dropdown align="end" className="main-dropdown">
                        <Dropdown.Toggle
                          variant="link"
                          bsPrefix="p-0"
                          id="dropdown-basic"
                          style={{
                            cursor: "pointer",
                            color: "black",
                            backgroundColor: "#f0f0f0",
                          }}
                        >
                          <ThreeDotsVertical />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => handleTourCardClick(tour)}
                          >
                            <EyeFill className="me-2" /> View
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleThreeDotsClick(tour)}
                          >
                            <PencilFill className="me-2" /> Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => confirmDeleteTour(tour)}
                          >
                            <TrashFill className="me-2" /> Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>

                  <div className="d-flex flex-column mt-2">
                    <span className="font-medium-title feature-title">
                      {tour.title}
                    </span>

                    <Row className="mb-5">
                      <div className="flex justify-between gap-3 mt-auto col-12 ">
                        <div className="d-inline font-feature-tour transformation-tour-description col-lg-3 col-4"></div>

                        <div
                          role="button"
                          className="float-end col-lg-4 col-4  location-text text-wrap font-common"
                          // onClick={() => handleShowModal(tour)}
                          onClick={() => handleTourScheduledCardClick(tour)}
                        >
                          <span
                            className="font-common "
                            style={{ color: "#0a66c2" }}
                          >
                            Schedule{" "}
                          </span>
                          <i className="mdi mdi-calendar-check "></i>
                        </div>

                        <div className="font-common">
                          <span
                            className="font-common "
                            onClick={() => uploadImages(tour)}
                            style={{ color: "#0a66c2", cursor: "pointer" }}
                          >
                            Upload image{" "}
                            <img
                              src={uploadImageIcon}
                              alt=""
                              style={{ width: "20px" }}
                            />
                          </span>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}
      <div className="text-end mt-1">
        {allTours && allTours.length > visibleToursCount && (
          <Button onClick={handleShowMoreTours}>Show More</Button>
        )}

        {visibleToursCount > 8 && (
          <Button onClick={handleShowLessTours} className="ms-2">
            Show Less
          </Button>
        )}
      </div>
      <Modal show={uploadImage} onHide={handleUploadImageClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {uploadedImageUrl && (
              <div className="text-center">
                <img
                  src={uploadedImageUrl}
                  alt="Uploaded Featured"
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "200px",
                    objectFit: "cover",
                  }}
                />
              </div>
            )}
            <Row className="mb-5">
              <Col md={6} className="mt-2">
                <Form.Label className="form-label">Featured Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleFeaturedImageChange}
                  ref={fileInputRefFeatured}
                  disabled={loadingFeatured}
                />
              </Col>

              <div>
                {displayedFile?.length > 0 ? (
                  displayedFile?.map((file, index) => (
                    <div key={index} className="text-center">
                      {file.name}
                      <DashCircle
                        className="ms-3"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => handleRemoveFile(index)}
                      />
                    </div>
                  ))
                ) : (
                  <div className="text-center text-muted"></div>
                )}
              </div>

              <Button
                className={`w-25 h-25 mt-3 ms-3 ${
                  displayedFile.length === 0 ? "custom-disabled" : ""
                }`}
                onClick={handleFeatured}
                disabled={displayedFile.length === 0 || loadingFeatured}
              >
                {loadingFeatured ? "Uploading..." : "Submit"}
              </Button>
            </Row>

            <Row className="mb-5">
              <Col md={6} className="mt-1">
                <Form.Label className="form-label">Snapshot Image</Form.Label>
                <Form.Control
                  type="file"
                  multiple
                  ref={fileInputRefSnapshots}
                  onChange={handleSnapshotImagesChange}
                  id="SNAPSHOT"
                />
              </Col>

              <div>
                {displayedSnapshotFiles.length > 0 ? (
                  displayedSnapshotFiles.map((file, index) => (
                    <div key={index} className="text-center">
                      {file.name}
                      <DashCircle
                        className="ms-3"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => handleSnapshotRemoveFile(index)}
                      />
                    </div>
                  ))
                ) : (
                  <div className="text-center text-muted"></div>
                )}
              </div>

              <Button
                className={`w-25 h-25 align-center mt-3 ms-3 ${
                  displayedSnapshotFiles.length === 0 ? "custom-disabled" : ""
                }`}
                onClick={() => handleSnapShot()}
                disabled={
                  displayedSnapshotFiles.length === 0 || loadingSnapshot
                }
              >
                {loadingSnapshot ? "Uploading..." : "Submit"}
              </Button>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      {/* Schedule Tour */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="font-common">Schedule Tour</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card style={{ border: "none" }}>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <div className="form-group font-common">
                  <Form.Group>
                    <Form.Label>Schedule Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="scheduleTitle"
                      value={formData.scheduleTitle}
                      onChange={handleChange}
                      placeholder="Enter schedule title"
                    />
                    {errors.scheduleTitle && (
                      <small className="text-danger">
                        {errors.scheduleTitle}
                      </small>
                    )}
                  </Form.Group>

                  <Row className="mt-1">
                    <Col md={6}>
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        id="startDate"
                        name="startDate"
                        value={formData.startDate}
                        min={new Date().toISOString().split("T")[0]}
                        onChange={handleChange}
                      />
                      {errors.startDate && (
                        <small className="text-danger">
                          {errors.startDate}
                        </small>
                      )}
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="duration">
                        <Form.Label>Duration in days</Form.Label>
                        <Form.Control
                          type="number"
                          name="duration"
                          placeholder="Enter duration in days "
                          value={formData.duration}
                          onChange={handleChange}
                        />
                        {errors.duration && (
                          <small className="text-danger">
                            {errors.duration}
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-1">
                    <Col md={6}>
                      <Form.Group controlId="durationTime">
                        <Form.Label>Duration in time</Form.Label>
                        <Form.Control
                          type="text"
                          name="durationTime"
                          placeholder="Enter Duration Time"
                          value={formData.durationTime}
                          onChange={handleChange}
                        />
                        {errors.durationTime && (
                          <small className="text-danger">
                            {errors.durationTime}
                          </small>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Label>Discount (%)</Form.Label>
                      <Form.Control
                        type="text"
                        id="discount"
                        name="discount"
                        placeholder="Enter discount (0-100)"
                        value={formData.discount}
                        onChange={handleChange}
                      />
                      {errors.discount && (
                        <small className="text-danger">{errors.discount}</small>
                      )}
                    </Col>
                  </Row>

                  <div className="row mt-1">
                    <div className="col-md-6 mb-3">
                      <Form.Label>Price</Form.Label>
                      <InputGroup>
                        <div style={{ width: "35%" }}>
                          <Select
                            id="currency"
                            name="currency"
                            options={currencyOptions}
                            value={currencyOptions.find(
                              (option) => option.value === formData.currency
                            )}
                            onChange={handleSelectChange}
                            placeholder="currency"
                            required
                            styles={customStyles}
                            formatOptionLabel={formatOptionLabel}
                          />
                        </div>
                        <Form.Control
                          type="text"
                          id="price"
                          name="price"
                          placeholder="Enter Price"
                          value={formData.price}
                          onChange={handleChange}
                        />
                      </InputGroup>
                      {errors.price && (
                        <small className="text-danger">{errors.price}</small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-3 d-flex justify-content-end">
                  <Button type="submit" className="me-2 btn-primary">
                    Submit
                  </Button>
                  <Button variant="secondary" onClick={handleCloseModal}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
      {showEditModal && (
        <TourEdit
          tour={selectedTour}
          handleUpdateTour={(updatedTour) => {
            const updatedTours = displayedTours.map((tour) =>
              tour.tourId === updatedTour.tourId ? updatedTour : tour
            );
            setDisplayedTours(updatedTours);
          }}
          handleCancelEdit={() => setShowEditModal(false)}
          onCloseAndRefresh={handleCloseEditModalAndRefresh}
        />
      )}
      <Modal
        show={showDeleteConfirmModal}
        onHide={handleCloseDeleteConfirmModal}
      >
        <Modal.Header closeButton className="font-common">
          <Modal.Title> Delete Tour</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this tour?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleDeleteTour}>
            Yes
          </Button>
          <Button variant="secondary" onClick={handleCloseDeleteConfirmModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={deleteImageConfirm} onHide={closeDeleteImageConfirmModal}>
        <Modal.Header closeButton className="font-common">
          <Modal.Title>Delete Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this image?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleDeleteImage}>
            Yes
          </Button>
          <Button variant="secondary" onClick={closeDeleteImageConfirmModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default TourDetails;
