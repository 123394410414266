import React, { useState, useEffect } from "react";
import AdminSidebar from "../adminSidebar";
import Navbar from "../../common/navbar";
import { Col, Form, Pagination } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BookedTours = () => {
  const baseUrl = process.env.REACT_APP_EUREKA_SERVER_ENDPOINT;
  const baseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;

  const [allCompany, setAllCompany] = useState([]);
  const [selectedEnterpriseCompanyId, setSelectedEnterpriseCompanyId] = useState("");
  const [toursByCompany, setToursByCompany] = useState([]);
  const [itemsPerPage] = useState(8); 
  const [currentPage, setCurrentPage] = useState(1);
  const [valueFromNavbar, setValueFromNavbar] = useState(false);

  const handleValueChange = (value) => {
    setValueFromNavbar(value);
  };

  useEffect(() => {
    getAllCompany();
  }, []);

  const getAllCompany = async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await fetch(`${baseUrl}/rest/auth/all/enterprise`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch companies");
      }
      const data = await response.json();
      setAllCompany(data.data);
    } catch (error) {
      console.error("Error fetching companies:", error);
      toast.error("Failed to fetch companies");
    }
  };

  const fetchAllToursByCompanyId = async (companyId) => {
    try {
      const token = localStorage.getItem("token"); 

      const response = await fetch(
        `${baseAPI}/tour/booking/enterprise/${companyId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error fetching tours: ${response.statusText}`);
      }
      const data = await response.json();
      setToursByCompany(data.data);
    } catch (error) {
      console.error("Error fetching tours:", error);
      toast.error("Failed to fetch tours");
    }
  };

  const handleInputChange = (e) => {
    setSelectedEnterpriseCompanyId(e.target.value);
  };

  useEffect(() => {
    if (selectedEnterpriseCompanyId) {
      fetchAllToursByCompanyId(selectedEnterpriseCompanyId);
      setCurrentPage(1); // Reset to the first page when company changes
    }
  }, [selectedEnterpriseCompanyId]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTours = toursByCompany.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(toursByCompany.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div>
        <div>
          <AdminSidebar />
        </div>
        <div>
          <Navbar onValueChange={handleValueChange} />
          <div
            className={`${
              !valueFromNavbar
                ? "layout-navbar-shifted"
                : "layout-navbar-full"
            }`}
          >
            <div className="layout-specing">
              <div className="row d-flex align-items-center justify-content-between">
                <div
                  className="col-8 mt-2 ms-3 me-4"
                  style={{ width: "100%" }}
                >
                  <div className="col-md-4">
                    <Form.Group
                      as={Col}
                      controlId="formCategory"
                      className="w-100 "
                    >
                      <Form.Label className="mt-3 mb-3">
                        Company Name:
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={selectedEnterpriseCompanyId}
                        onChange={handleInputChange}
                        name="companyName"
                        className="border-1 border-dark"
                      >
                        <option value="">Select company name</option>
                        {allCompany?.map((company) => (
                          <option
                            key={company.enterpriseCompanyId}
                            value={company.enterpriseCompanyId}
                          >
                            {company.companyName}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </div>

                  <div className="table-responsive shadow rounded mt-4 me-5">
                    <table className="table table-center bg-white mb-0">
                      <thead>
                        <tr>
                          <th className="border-bottom p-3">Sr.no</th>
                          <th className="border-bottom p-3">
                            Number Of Participants
                          </th>
                          <th className="border-bottom p-3 text-center">
                            Booking Date
                          </th>
                          <th className="border-bottom p-3 text-center">
                            Tour Name
                          </th>
                          <th className="border-bottom p-3 text-center">
                            User Name
                          </th>
                          <th className="border-bottom p-3 text-center">
                            Mobile Number
                          </th>
                          <th className="border-bottom p-3 text-center">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentTours.length > 0 ? (
                          currentTours.map((tour, index) => (
                            <tr key={index}>
                              <td>{indexOfFirstItem + index + 1}</td>
                              <td>{tour.numberOfParticipants}</td>
                              <td className="text-center">
                                {new Date(
                                  tour.bookingDate
                                ).toLocaleDateString()}
                              </td>
                              <td>{tour.schedule.tour?.title}</td>
                              <td>{tour?.schedule.user?.firstName}</td>
                              <td className="text-center">
                                {tour?.schedule.user?.mobileNumber}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="tour-btn-green border-0 bg-success text-white"
                                  style={{ cursor: "none", fontSize: "12px" }}
                                >
                                  {tour?.status}
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7" className="text-center">
                              No tours available.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* Pagination */}
                  <Pagination className="mt-3 justify-content-center">
                    <Pagination.Prev
                      onClick={() => currentPage > 1 && paginate(currentPage - 1)}
                      disabled={currentPage === 1}
                    />
                    {Array.from({ length: totalPages }, (_, i) => (
                      <Pagination.Item
                        key={i + 1}
                        active={i + 1 === currentPage}
                        onClick={() => paginate(i + 1)}
                      >
                        {i + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() => currentPage < totalPages && paginate(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    />
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookedTours;