import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import reducers from './reducers'; 

// Persist config for redux-persist
const persistConfig = {
  key: 'root',
  storage,
};

// Persisted reducer
const persistedReducer = persistReducer(persistConfig, reducers);

// Create the store with the persisted reducer
const store = createStore(persistedReducer);

// Create the persistor
const persistor = persistStore(store);

// Export the store and persistor
export { store, persistor };
